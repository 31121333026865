import { useState } from 'react';
import Button from '@mui/material/Button';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from '@mui/material';
import {
  ProductionEditDialogModel,
  ProductionEditDialoDialogController,
  ProductionEdit,
} from '../order/edit/prodActivities.dialog';

export const ProdStatusControl = (props) => {

  const { controller } = props;
  const [productionEditDialogModel, setProductionEditDialogModel] = useState(new ProductionEditDialogModel());
  const productionEditDialogController = new ProductionEditDialoDialogController(productionEditDialogModel, setProductionEditDialogModel);

  /**
   * 
   * @param {*} item 
   */
  const onEdit = async (item) => {
    const result = await productionEditDialogController.show(item);
    if (result) controller.refreshGvProdActivities();
  };

  return (
    <div className='w-full flex flex-col'>
      <div style={{ fontSize: '1.2em', fontWeight: 600 }}>
        Production Activities
      </div>
      <Table stickyHeader size='small'>
        <TableHead>
          <TableRow>
            <TableCell component='th' scope='row' align='center' width={50}></TableCell>
            <TableCell component='th' scope='row' align='center'>
              Production
            </TableCell>
            <TableCell component='th' scope='row' align='center'>
              Receive Date
            </TableCell>
            <TableCell component='th' scope='row' align='center'>
              Cut Date
            </TableCell>
            <TableCell component='th' scope='row' align='center'>
              Completed Date
            </TableCell>
            <TableCell component='th' scope='row' align='center'>
              Cleaners Date
            </TableCell>
            <TableCell component='th' scope='row' align='center'>
              Ship Date
            </TableCell>
            <TableCell component='th' scope='row' align='center'>
              Arrive Date
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {controller.productions().map((item) => (
            <TableRow key={item.productionID}>
              <TableCell className='border-left border-top border-right' component='td' scope='row' align='center'>
                <Button variant='contained' color='secondary' onClick={() => onEdit(item)}>
                  Update Dates
                </Button>
              </TableCell>
              <TableCell className='border-top border-right' component='td' scope='row' align='left'>
                {item.prodtype}
              </TableCell>
              <TableCell className='border-top border-right' component='td' scope='row' align='left'>
                <p style={{ fontSize: '0.85em' }} dangerouslySetInnerHTML={{ __html: item.receiveDateInfo }} />
              </TableCell>
              <TableCell className='border-top border-right' component='td' scope='row' align='left'>
                <p style={{ fontSize: '0.85em' }} dangerouslySetInnerHTML={{ __html: item.cutDateInfo }} />
              </TableCell>
              <TableCell className='border-top border-right' component='td' scope='row' align='left'>
                <p style={{ fontSize: '0.85em' }} dangerouslySetInnerHTML={{ __html: item.completedDateInfo }} />
              </TableCell>
              <TableCell className='border-top border-right' component='td' scope='row' align='left'>
                <p style={{ fontSize: '0.85em' }} dangerouslySetInnerHTML={{ __html: item.cleanersDateInfo }} />
              </TableCell>
              <TableCell className='border-top border-right' component='td' scope='row' align='left'>
                <p style={{ fontSize: '0.85em' }} dangerouslySetInnerHTML={{ __html: item.shipDateInfo }} />
              </TableCell>
              <TableCell className='border-top border-right' component='td' scope='row' align='left'>
                <p style={{ fontSize: '0.85em' }} dangerouslySetInnerHTML={{ __html: item.arriveDateInfo }} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ProductionEdit controller={productionEditDialogController} />
    </div>
  );
};
