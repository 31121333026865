import React, { useContext, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { DatePicker } from "src/app/@core/forms/controls/datePicker";
import FusePageCarded from "src/app/@core/@fuse/FusePageCarded.FullWidth";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import { ModuleContext, initialModuleData, moduleDataContext } from "src/app/@core/@contexts/module.context";
import { saveXLSX, toAPIDate } from "src/app/@core/common";
import { DomainContext } from "src/app/@core/@contexts/domain.context";
import { TBodyCell } from "src/app/@core/forms/dataTable/dataTable.control";
import { ReportBody, ReportHeader } from "src/app/@core/forms/report/report.control";
import { InventoryEdit, InventoryEditDialogModel, InventoryEditDialogController } from 'src/app/@domain/modules/inventory/inventory.edit';
import { TextSelectMultiInput } from 'src/app/@core/forms/controls/textSelectMultiInput';
import { comboLists } from "src/app/@domain/consts/comboLists";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { 
  ShirtsInProductionBreakdownBySalespersonReportDialogController,
  ShirtsInProductionBreakdownBySalespersonReportDialogModel,
  ShirtsInProductionBreakdownBySalespersonReportDialog
 } from "./shirtsInProductionBreakdownBySalespersonReport.dialog";

/**
 * 
 * @returns 
 */
export const ReportSaleByRetailerBySalespersonPage = () => {

  const frmModel = useForm({ mode: "onChange", defaultValues: {} });
  initialModuleData.loadFormModel(frmModel);

  const [store, setStore] = useState(initialModuleData);
  const [gvItems, setGvItems] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState(null);

  const { api, spinner, dropdown } = useContext(DomainContext);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));

  const frmCriteria = useForm({
    mode: "onChange", defaultValues: {
      startDate: new Date().setMonth(new Date().getMonth() - 6),
      endDate: new Date(),
      availability: ''
    }
  });

  const { control, getValues } = frmCriteria;

  const [shirtsInProductionBreakdownBySalespersonReportDialogModel,
    setShirtsInProductionBreakdownBySalespersonReportDialogModel
  ] = useState(new ShirtsInProductionBreakdownBySalespersonReportDialogModel());
  const shirtsInProductionBreakdownBySalespersonReportDialogController =
    new ShirtsInProductionBreakdownBySalespersonReportDialogController(shirtsInProductionBreakdownBySalespersonReportDialogModel,
      setShirtsInProductionBreakdownBySalespersonReportDialogModel);

  const [retailers, setRetailers] = useState([]);
  const [selectedRetailers, setSelectedRetailers] = useState([]);
  const [selectedShirtTypes, setSelectedShirtTypes] = useState([]);

  /**
   * 
   */
  useEffect(() => {
    loadRetailers();
    return () => { };
  }, []);

  /**
   * 
   */
  const loadRetailers = async () => {
    const result = await api.retailerApi.getDropdownItems();
    result.forEach(x => x.storeName = x.name.substring(0, x.name.indexOf("-")).trim());
    setRetailers(result);
  }

  /**
   * 
   * @param {*} data 
   */
  const loadData = async data => {

    try {
      spinner.show();
      const result = await api.reportApi.getSalesByRetailerBySalesperson(data);
      result.forEach(x => x.open = false);
      setGvItems(result);
      moduleDataContext.moduleGvData(result);
    } catch (err) { console.log(err); }
    finally { spinner.hide(); }

  };

  /**
   * 
   * @param {*} item 
   */
  const expandRetailer = item => {

    const idx = gvItems.findIndex(x => x.itemID === item.itemID);
    gvItems[idx].open = !gvItems[idx].open
    setGvItems(gvItems);
    moduleDataContext.moduleGvData(gvItems);

 }

  /**
   *
   */
  const contextObj = useMemo(() => {
    moduleDataContext.bootstrap(store, setStore);
    moduleDataContext.loadModelDataFunc = loadData;
    return moduleDataContext;
  }, [store]);

  /**
   *
   */
  const onSearch = async () => {

    const obj = getValues();
    obj.retailers = selectedRetailers;
    obj.shirtTypes = selectedShirtTypes;

    const criteria = {
      startDate: toAPIDate(obj.startDate),
      endDate: toAPIDate(obj.endDate),
      retailers: selectedRetailers,
      shirtTypes: selectedShirtTypes
    };
    setSearchCriteria(criteria);
    loadData(criteria);

  };

  /**
   *
   */
  const onExport = async () => {
    try {
      spinner.show();
      const result = await api.reportApi.exportSalesByRetailerBySalesperson(searchCriteria);
      saveXLSX(result, 'SalesByRetailerBySalesperson');
    } catch (err) { console.log(err); }
    finally { spinner.hide(); }
  };

  const columns = [
    { id: "action", align: "center", disablePadding: false, label: "", sort: false, width: 50 },
    { id: "retailerShortName", align: "center", disablePadding: false, label: "Retailer Name", sort: true, width: 300 },
    { id: "salespersonName", align: "center", disablePadding: false, label: "Salesperson Name", sort: true, width: 300 },
    { id: "quantity", align: "center", disablePadding: false, label: "Current Period", sort: true, width: 150 },
    { id: "pastQuantity", align: "center", disablePadding: false, label: "Prior Period", sort: true, width: 150, },
    { id: "percentageChange", align: "center", disablePadding: false, label: "% Change", sort: true, width: 150, },
    { id: "empty", align: "center", disablePadding: false, label: "" },
  ];

  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const ReportCriteria = props => {

    const { frmCriteria } = props;

    return <FormProvider {...frmCriteria}>
      <div style={{ flex: 1, display: "flex" }}>
        <DatePicker id="startDate" label="Report Start Date" control={control} />
        &nbsp;
        <DatePicker id="endDate" label="Report End Date" control={control} />
        &nbsp;
        <TextSelectMultiInput id="retailers" label="Retailers" control={control} items={retailers} title="Select Retailers"
          selectedItems={selectedRetailers} setSelectedItems={setSelectedRetailers} />
        &nbsp;
        <TextSelectMultiInput id="shirtTypes" label="Shirt Types" control={control} items={comboLists.reportShirtTypes} title="Select Shirt Types"
          selectedItems={selectedShirtTypes} setSelectedItems={setSelectedShirtTypes} />
      </div>
    </FormProvider>

  }

  /**
   * 
   * @param {*} val 
   * @returns 
   */
  const formatDecimal = val => {

    if (val === null) return "Infinity";

    var s = Number(val / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 });
    return s;
  }

  /**
   * 
   * @param {*} salesperson 
   */
  const onQuantityClick = salesperson => {

    const criteria = {
      startDate: searchCriteria.startDate,
      endDate: searchCriteria.endDate,
      salesperson: salesperson.salespersonName,
      retailers: searchCriteria.retailers,
      shirtTypes: searchCriteria.shirtTypes,
    };

    shirtsInProductionBreakdownBySalespersonReportDialogController.show(criteria)

  }

  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const QuantityCell = props => {
    const { salesperson } = props;
    return <a style={{ cursor: 'pointer' }} onClick={() => onQuantityClick(salesperson)}>{salesperson.quantity}</a>
  }

  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const ReportRow = props => {

    const { item } = props;

    return (
      <React.Fragment>
        <TableRow hover tabIndex={-1} key={item.itemID}>
          <TableCell>
            <span onClick={() => expandRetailer(item)}>
              {item.open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </span>
          </TableCell>
          <TBodyCell align="left" value={item.retailerShortName} />
          <TBodyCell align="left" value={item.salespersonName} />
          <TBodyCell align="center" value={item.quantity} />
          <TBodyCell align="center" value={item.pastQuantity} />
          <TBodyCell align="center" value={formatDecimal(item.change)} />
          <TBodyCell align="center" value="" />
        </TableRow>
        {
          item.open && (item.salespersons.map((salesperson) => (
            <TableRow key={salesperson.itemID}>
              <TableCell component="th" scope="row">
              </TableCell>
              <TBodyCell align="left" value={""} />
              <TBodyCell align="left" value={salesperson.salespersonName} />
              <TBodyCell align="center" value={<QuantityCell salesperson={salesperson} />} />
              <TBodyCell align="center" value={salesperson.pastQuantity} />
              <TBodyCell align="center" value={formatDecimal(salesperson.change)} />
              <TBodyCell align="center" value="" />
            </TableRow>
          )))

        }
      </React.Fragment>
    );
  }

  return <ModuleContext.Provider value={contextObj}>
    <ShirtsInProductionBreakdownBySalespersonReportDialog controller={shirtsInProductionBreakdownBySalespersonReportDialogController} />
    <FusePageCarded
      header={<ReportHeader title="Sales By Retailer By Salesperson" reportCriteria={<ReportCriteria frmCriteria={frmCriteria} />} onSearch={onSearch} />}
      content={<ReportBody columns={columns} gvItems={gvItems} onExport={onExport} ReportRow={ReportRow} />}
      scroll={isMobile ? "normal" : "content"}
    />
  </ModuleContext.Provider>

};
