import { APIBase } from "./@base.api";

export class ContactApi extends APIBase {
  /**
   *
   */
  constructor(context) {
    super(context);
    this.endPoints = {
      getAll: "Contact/GetAll",
      getAllSP: "Contact/GetAllSP",
      insert: "Contact/Insert",
      update: "Contact/Update",
      delete: "Contact/Delete",
      get: "Contact/get",
      addSalesPerson: "Contact/AddSalesPerson",
      getShirtUsers: "Contact/GetShirtUsers",
      getDropdownItems: "Contact/GetDropdownItems",
      getEditOrderShirtUsers: "Contact/GetEditOrderShirtUsers",
    };
  }

  /**
   * 
   * @returns 
   */
  addSalesPerson = obj => this.httpPost(this.endPoints.addSalesPerson, obj);
  
  /**
   * 
   * @returns 
   */
  getEditOrderShirtUsers = () => this.httpGet(this.endPoints.getEditOrderShirtUsers);

  /**
   * 
   * @returns 
   */
  getDropdownItems = () => this.httpGet(this.endPoints.getDropdownItems);

  /**
   * 
   * @returns 
   */
  getShirtUsers = () => this.httpGet(this.endPoints.getShirtUsers);

  /**
   *
   * @returns
   */
  getAllSP = () => this.httpGet(this.endPoints.getAllSP);
}
