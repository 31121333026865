import { useEffect } from 'react';
import { FormProvider, Controller } from "react-hook-form";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import TablePagination from "@mui/material/TablePagination";
import Tooltip from "@mui/material/Tooltip";
import FuseSvgIcon from 'src/@fuse/core/FuseSvgIcon';
import { isUNE } from "src/app/@core/common";

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const TPagination = props => {

  const { gvItems, rowsPerPage, page, onChangePage, onChangeRowsPerPage } = props;

  return <TablePagination
    className="shrink-0"
    component="div"
    count={isUNE(gvItems) ? 0 : gvItems.length}
    rowsPerPage={rowsPerPage()}
    page={page()}
    onPageChange={onChangePage}
    onRowsPerPageChange={onChangeRowsPerPage}
  />

}

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const THead = props => {

  const { columns, sortOrder, onSort } = props;

  return <TableHead>
    <TableRow className="h-48 sm:h-64">
      {columns.map(x => <THeadCell key={x.id} column={x} sortOrder={sortOrder} onSort={onSort} />)}
    </TableRow>
  </TableHead>

}

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const THeadWithFilter = props => {

  const { columns, sortOrder, onSort, frmFilter } = props;

  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const FilterBox = props => {

    const { id, label, control } = props;
    const cssFilter = { width: '80px', margin: 0, padding: '0 5px' };

    /**
     * 
     */
    useEffect(() => {
      const field = frmFilter.getValues().orderID;
      if (field && field.focus) field.ref?.focus();
    }, []);

    /**
     * 
     * @param {*} e 
     * @param {*} fieldProps 
     * @returns 
     */
    const refChange = (e, fieldProps) => fieldProps.value.ref = e ? e : null

    /**
     * 
     * @param {*} e 
     * @param {*} fieldProps 
     * @returns 
     */
    const fieldChange = (e, fieldProps) => fieldProps.onChange(isNaN(e.target.value) ? fieldProps.value : { ...fieldProps.value, value: e.target.value });

    /**
     * 
     * @param {*} e 
     * @param {*} fieldProps 
     * @returns 
     */
    const fieldFocus = (e, fieldProps) => fieldProps.value.focus = true;

    /**
     * 
     * @param {*} e 
     * @param {*} fieldProps 
     * @returns 
     */
    const fieldBlur = (e, fieldProps) => fieldProps.value.focus = false;

    return <Controller
      name={id}
      control={control}
      render={({ field: { ref, ...props } }) => (
        <input type="text" id={id} label={label} style={cssFilter} maxLength={8} {...props}
          value={props.value.value}
          ref={e => refChange(e, props)}
          onChange={e => fieldChange(e, props)}
          onFocus={e => fieldFocus(e, props)}
          onBlur={e => fieldBlur(e, props)}
        />
      )}
    />

  }

  return <TableHead>
    <TableRow className="h-48 sm:h-64">
      {columns.map(x => <THeadCell key={x.id} column={x} sortOrder={sortOrder} onSort={onSort} />)}
    </TableRow>
    <TableRow>
      <FormProvider>
        {columns.map(x => <TableCell key={x.id}>
          {x.id === 'orderID' ? <FilterBox id="orderID" label="OrderID" control={frmFilter.control} /> : ""}
        </TableCell>)}
      </FormProvider>
    </TableRow>
  </TableHead>

}

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const THeadCell = props => {

  const { column, sortOrder, onSort } = props;

  return <TableCell
    width={column.width}
    style={{ backgroundColor: "#e1e1e1" }}
    className="p-4 md:p-5"
    align="center"
    padding={column.disablePadding ? "none" : "normal"}
    sortDirection={sortOrder.id === column.id ? sortOrder.direction : false}
  >
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1 }}>
        {column.sort && (
          <Tooltip title="Sort" placement={column.align === "right" ? "bottom-end" : "bottom-start"} enterDelay={300}>
            <TableSortLabel active={sortOrder.id === column.id} direction={sortOrder.direction} onClick={() => onSort(column.id)} className="font-semibold">
              {column.label}
            </TableSortLabel>
          </Tooltip>
        )}
      </div>
      {
        column.filter ? column.filter : null
      }
    </div>
  </TableCell>

}

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const TBodyCell = props => {

  const { align, value } = props;

  return <TableCell className="p-4 md:p-5 tbody-cell" component="td" scope="row" align={align} style={{verticalAlign: 'top'}}>
    {value}
  </TableCell>
}

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const TBodyCellReport = props => {

  const { onClick } = props;

  return <TableCell className="p-4 md:p-5 tbody-cell" component="td" scope="row" align="center">
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <FuseSvgIcon className='text-48 cursor-pointer' size={20} color='action' onClick={onClick}>
        material-solid:article
      </FuseSvgIcon>
    </div>
  </TableCell>
}

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const TBodyCellEdit = props => {

  const { onEdit } = props;

  return <TableCell className="p-4 md:p-5 tbody-cell" component="td" scope="row" align="center">
    <FuseSvgIcon className='text-48 cursor-pointer' size={20} color='action' onClick={onEdit}>
      material-solid:edit
    </FuseSvgIcon>
  </TableCell>
}
