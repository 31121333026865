import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";

export const TextInput = (props) => {

  const { id, label, control, style, disabled = false, rules, defaultValue = '', value = '', maxLength = -1,
    onKeyDownHandler
  } = props;

  /**
   * 
   * @param {*} e 
   * @returns 
   */
  const keyDownHandler = e => {
    if (!onKeyDownHandler) return;
    onKeyDownHandler(e);
  }

  if (!control) {
    return <TextField
      size="small"
      label={label}
      className="mt-8 mb-2 mx-8"
      fullWidth
      variant="outlined"
      disabled={disabled}
      inputProps={{ maxLength: maxLength }}
      style={style}
      value={value}
    />
  } else {
    return <Controller
      name={id}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        return (
          <TextField
            helperText={error ? error.message : null}
            error={!!error}
            size="small"
            id={id}
            label={label}
            className="mt-8 mb-2 mx-8"
            fullWidth
            variant="outlined"
            disabled={disabled}
            inputProps={{ maxLength: maxLength }}
            onKeyDown={keyDownHandler}
            style={style}
            {...field}
          />
        );
      }}
    />
  }
};
