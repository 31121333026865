export class APIBase {

  /**
   * 
   * @param {*} context 
   */
  constructor(context) {
    this.context = context;
    this.endPoints = [];
    this.baseUrl = process.env.REACT_APP_API_URL;
  }

  /**
   * 
   * @returns 
   */
  getInitialData = () => this.httpGet(this.endPoints.getInitialData);

  /**
   * 
   * @param {*} data 
   * @returns 
   */
  insert = data => this.httpPost(this.endPoints.insert, data);

  /**
   * 
   * @param {*} data 
   * @returns 
   */
  update = data => this.httpPost(this.endPoints.update, data);

  /**
   * 
   * @param {*} id 
   * @returns 
   */
  delete = id => this.httpPost(this.endPoints.delete, id);

  /**
   * 
   * @param {*} id 
   * @returns 
   */
  get = id => this.httpGet(`${this.endPoints.get}?id=${id}`);

  /**
   * 
   * @returns 
   */
  getAll = () => this.httpGet(this.endPoints.getAll);

  /**
   * 
   * @returns 
   */
  getAccessToken = () => window.localStorage.getItem('jwt_access_token');

  /**
   *
   * @param {any} endpoint
   */
  httpGet = async endpoint => {

    const url = this.baseUrl + endpoint;
    const promise = fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
      redirect: "follow",
      referrer: "no-referrer",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + this.getAccessToken()
      },
    }).then(res => {
      if (res.ok) return res.json().then(result => {
        if (result.succeeded) return result.data;
        // this.context.error.show(result.error);
        return null;
      });
    }).catch(err => {
      // this.context.error.show('Cannot connect to the server');
    });
    return promise

  }

  /**
   * 
   * @param {*} endpoint 
   * @param {*} data 
   * @returns 
   */
  httpPost = async (endpoint, data) => {

    const url = this.baseUrl + endpoint;
    const promise = fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
      redirect: "follow",
      referrer: "no-referrer",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + this.getAccessToken()
      },
      body: JSON.stringify(data),
    }).then(res => {
      if (res.ok) return res.json().then(result => {
        if (result.succeeded) return result.data;
        console.log(this.context)
        console.log(this.context.error)
        // this.context.error.show(result.error);
        return null;
      });
    }).catch(err => {
      // this.context.error.show('Cannot connect to the server');
    });
    return promise;

  }

  /**
   * 
   * @param {*} endpoint 
   * @param {*} data 
   * @returns 
   */
  httpDownload = (endpoint, data) => {

    const url = this.baseUrl + endpoint;
    const promise = fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
      redirect: "follow",
      referrer: "no-referrer",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + this.getAccessToken()
      },
      body: JSON.stringify(data),
    })
      .then(res => {
        console.log
        if (res.ok) return res.blob();
      }).catch(err => {
        this.context.errorOverlay.show('Cannot connect to the server');
      });
    return promise

  }

  httpUpload = (endPoint, data) => {

    const promise = fetch(this.baseUrl + endPoint, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
      redirect: "follow",
      referrer: "no-referrer",
      headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded',
        "Authorization": "Bearer " + this.getAccessToken()
      },
      body: data,
    }).then(res => {
      if (res.ok) return res.json().then(result => result.data);
    }).catch(err => {
      this.context.errorOverlay.show('Cannot connect to the server');
    });
    return promise;
  }

  /**
   * 
   * @param {*} endPoint 
   * @param {*} data 
   * @returns 
   */
  // httpUpload = (endPoint, data) => {

  //   const promise = fetch(this.baseUrl + endPoint, {
  //     method: "POST",
  //     mode: "cors",
  //     cache: "no-cache",
  //     credentials: "omit",
  //     redirect: "follow",
  //     referrer: "no-referrer",
  //     headers: {
  //       'Content-Type': 'application/json',
  //       "Authorization": "Bearer " + this.getAccessToken()
  //     },
  //     body: data,
  //   }).then(res => {
  //     if (res.ok) return res.json().then(result => result.data);
  //   }).catch(err => {
  //     this.context.errorOverlay.show('Cannot connect to the server');
  //   });
  //   return promise;

  // }

  /**
   * 
   * @param {*} endpoint 
   * @param {*} data 
   * @returns 
   */
  getPdf = async (endpoint, data) => {

    const url = this.baseUrl + endpoint;
    const promise = fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
      redirect: "follow",
      referrer: "no-referrer",
      headers: {
        "Content-Type": "application/json",
        // "Authorization": "Bearer " + localStorage.getItem(STORAGE_TOKEN)
      },
      body: JSON.stringify(data)
    }).then(response => response.blob());
    // .then(blob => {
    //   const url = window.URL.createObjectURL(
    //     new Blob([blob]),
    //   );
    //   const link = document.createElement('a');
    //   link.href = url;
    //   link.setAttribute(
    //     'download',
    //     data["formName"] + `.pdf`,
    //   );
    //   document.body.appendChild(link);
    //   link.click();
    //   link.parentNode.removeChild(link);
    // });

    return promise;
  }


}