import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TextInput } from "src/app/@core/forms/controls/textInput";
import { TextArea } from "src/app/@core/forms/controls/textArea";
import { DialogHeaderContent } from "src/app/@core/forms/controls/dialogHeaderContent";
import { DialogBase, DialogModelBase } from "src/app/@core/forms/dialog/@dialogBaseV2";
import { prodStatusConst } from "src/app/@domain/consts/prodStatus.const";
import { getFullName } from "src/app/@core/common";

export class OrderAdvanceDialogModel extends DialogModelBase {

  /**
   * 
   */
  constructor() {
    super();
    this.order = null;
    this.title = '';
    this.notes = '';
    this.contactName = '';
    this.retailerName = '';
    this.status = '';
    this.nextStatus = 0;
  }

}

export class OrderAdvanceDialogController extends DialogBase {

  /**
   * 
   * @param {*} model 
   * @param {*} setModel 
   */
  constructor(model, setModel) {
    super(model, setModel);
  }

  /**
   * 
   */
  submit = async approveTrial => {
    this.hide({
      orderID: this.model.order().orderID,
      notes: this.model.notes,
      status: this.model.status,
      approveTrial: approveTrial
    });
  }

  /**
   * 
   * @returns 
   */
  show = async (nextStatus, order, contact, retailer) => {
  
    const cutTrial = order().isCutTrial;
    let title = '';
    let notes = '';
    let contactName = getFullName(contact());
    let retailerName = retailer().name;
    let status = '';

    if (cutTrial === 'No') {

      if (nextStatus === prodStatusConst.NoTrialCut) {
        title = 'Advance from Received to Cut';
        notes = 'Cut'; // Cut
        status = 'Cut';
      } else if (nextStatus === prodStatusConst.NoTrialOrTrialComplete) {
        title = 'Advance from Cut to Complete';
        notes = 'Complete';
        status = 'Complete'
      }

    } if (cutTrial === 'Yes') {

      if (nextStatus === prodStatusConst.TrialCut) {
        title = 'Advance from Received to Cut';
        notes = 'Trial Cut';
        status = 'Trial Cut';
      } else if (nextStatus === prodStatusConst.NoTrialOrTrialComplete) {
        title = 'Advance from Cut to Complete';
        notes = 'Trial Complete';
        status = 'Trial Complete'
      } else if (nextStatus === prodStatusConst.PostTrialReceived) {
        title = 'Approve Trial Shirt';
        notes = 'Trial Approved- Production Complete';
        status = 'Post Trial Received'
      } else if (nextStatus === prodStatusConst.PostTrialCut) {
        title = 'Advance from Receive to Cut';
        notes = 'Post Trial Cut';
        status = 'Post Trial Cut'
      } else if (nextStatus === prodStatusConst.PostTrialComplete) {
        title = 'Advance from Cut to Complete';
        notes = 'Post Trial Complete';
        status = 'Post Trial Complete'
      }

    }
     
    return super.show(x => {
      x.order = order;
      x.title = title;
      x.notes = notes;
      x.contactName = contactName;
      x.retailerName = retailerName;
      x.status = status;
      x.nextStatus = nextStatus;
    });
  }

}

/**
 *
 * @param {*} props
 * @returns
 */
export const OrderAdvanceDialog = props => {

  const { controller } = props;

  return (
    <Dialog
      fullScreen
      style={{ margin: "auto", maxWidth: 500, maxHeight: 400 }}
      open={controller.visible()}
      onClose={controller.close}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <DialogHeaderContent title={controller.model.title} onClose={controller.close} />
      </DialogTitle>
      <DialogContent dividers={true}>
        <div className="flex flex-col" style={{ marginRight: 20 }}>
          <TextInput label="Contact" value={controller.model.contactName} disabled={true} />
          <TextInput label="Retailer" value={controller.model.retailerName} disabled={true} />
          <TextArea label="Please confirm notes" value={controller.model.notes} disabled={true} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={controller.close}>Cancel</Button>
        { controller.model.nextStatus == prodStatusConst.PostTrialReceived &&
          <Button variant="contained" color="error" onClick={() => controller.submit(false)}>No</Button>
        }
        <Button variant="contained" color="secondary" onClick={() => controller.submit(true)}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};
