import { useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormProvider, useForm } from 'react-hook-form';
import { TextInput } from 'src/app/@core/forms/controls/textInput';
import { SelectInput } from 'src/app/@core/forms/controls/selectInput';
import { DialogBase, DialogModelBase, } from 'src/app/@core/forms/dialog/@dialogBaseV2';
import { DialogHeaderContent } from 'src/app/@core/forms/controls/dialogHeaderContent';
import { DomainContext } from 'src/app/@core/@contexts/domain.context';
import { comboLists } from "src/app/@domain/consts/comboLists";

const fabricModel = { fabricID: '', quantity: 1, trial: false };

/**
 *
 */
export class ShirtsAddDialogModel extends DialogModelBase {

  /**
   *
   */
  constructor() {
    super();
    this.shirtType = { value: '' };
    this.prodType = { value: '' };
    this.frmLocal = useForm({
      mode: 'onChange',
      defaultValues: {
        fabrics: Array(20).fill(fabricModel)
      }
    });
    this.order = null;
    this.prodTypes = [];

  }
}
/**
 *
 */
export class ShirtsAddDialogController extends DialogBase {

  /**
   *
   * @param {*} model
   * @param {*} setModel
   */
  constructor(model, setModel) {
    super(model, setModel);
  }

  /**
   * 
   */
  loadModel = () => {
    model.frmLocal.setValue('fabrics', Array(20).fill(fabricModel));
  }

  /**
   * 
   * @param {*} e 
   */
  prodTypeChange = e => {
    console.log(e.target);
    this.updateModel(x => x.prodType = e.target);
  }

  /**
   * 
   * @param {*} e 
   */
  shirtTypeChange = e => this.updateModel(x => x.shirtType = e.target);

  /**
   * 
   * @returns 
   */
  validateFabrics = async () => {

    const dto = this.model.frmLocal.getValues();
    const fabrics = await this.api().orderApi.getEditOrderShirts(dto);
    if (fabrics.length === 0) return true;
    this.messageBox().showError('Invalid Fabric IDs: ' + fabrics.toString());
    return false;

  }

  /**
   * 
   * @returns 
   */
  validateForm = async () => {

    try {

      if (this.model.shirtType.value.length === 0) {
        this.messageBox().showError('Please select a Shirt Type.');
        return false;
      }
  
      if (this.model.prodType.value.length === 0) {
        this.messageBox().showError('Please select a Production Type.');
        return false;
      }
  
      const fabrics = this.model.frmLocal.getValues().fabrics;
      const hasFabric = fabrics.reduce((res, fabric) => res + fabric.fabricID.trim(), '');
      if (!hasFabric) {
        this.messageBox().showError('Fabric ID is required to submit this form.');
        return false;
      }
  
      // if (!await this.validateFabrics()) return false;
  
      return true;

    } catch (err) { 
      console.log(err); 
      return false;
    }
    

  }

  /**
   * 
   */
  submit = async () => {

    try {

      if (!await this.validateForm()) return;

      this.spinner().show();

      const dto = {
        orderID: this.model.order().orderID,
        shirtType: this.model.shirtType.value,
        prodType: this.model.prodType.value,
        fabrics: this.model.frmLocal.getValues().fabrics.filter(x => x.fabricID.trim().length > 0)
      }

      console.log(dto);

      const result = await this.api().orderApi.addFabricsToOrder(dto);
      this.hide(result);

    } catch (err) { console.log(err); }
    finally { this.spinner().hide(); }
  };

  /**
   * 
   * @param {*} controller 
   * @returns 
   */
  show = controller => {

    const prodTypes = [... new Set(controller.shirts().map(x => x.prodType))]
      .map(x => { return { id: x, name: x } });

    this.model.frmLocal.reset({ fabrics: Array(20).fill(fabricModel) });

    return super.show(x => {
      x.order = controller.order;
      x.shirtType = { value: '' };
      x.prodTypes = prodTypes;
      x.prodType = prodTypes[0];
    });

  };
}
/**
 *
 * @param {*} props
 * @returns
 */
export const ShirtsAddDialog = (props) => {

  const { controller } = props;
  const { shirtType, prodType, prodTypes, frmLocal } = controller.model;
  const { control } = frmLocal;

  const { dropdown } = useContext(DomainContext);

  /**
   * 
   */
  const loadFabricRows = () => {
    const rows = [];
    for (let i = 0; i < 10; i++) {
      rows.push(
        <div key={i} className='flex flex-row'>
          <TextInput id={`fabrics[${i * 2}].quantity`} label='Qty' control={control} style={{ width: 200 }} />
          <TextInput id={`fabrics[${i * 2}].fabricID`} label='Fabric ID' control={control} />
          <TextInput id={`fabrics[${i * 2 + 1}].quantity`} label='Qty' control={control} style={{ width: 200, marginLeft: 20 }} />
          <TextInput id={`fabrics[${i * 2 + 1}].fabricID`} label='Fabric ID' control={control} />
        </div>
      );
    }
    return rows;
  }

  return (
    <Dialog
      fullScreen
      style={{ margin: 'auto', maxWidth: 700, maxHeight: 750 }}
      open={controller.visible()}
      onClose={controller.close}
      scroll='paper'
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
    >
      <DialogTitle id='scroll-dialog-title'>
        <DialogHeaderContent title="Add Shirts" onClose={controller.close} />
      </DialogTitle>
      <DialogContent dividers={true}>
        <div className='w-full flex flex-row'>
          <SelectInput label="Shirt Types" defaultValue={shirtType.value} items={comboLists.shirtTypes} onChange={controller.shirtTypeChange} />
          <SelectInput label='Production Types' defaultValue={prodType.value} items={prodTypes} onChange={controller.prodTypeChange} />
        </div>
        <hr style={{ marginTop: 10, marginBottom: 10 }} />
        <div className='w-full flex flex-col'>
          <FormProvider {...frmLocal}>
            {loadFabricRows()}
          </FormProvider>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='primary' onClick={controller.close}>
          Cancel
        </Button>
        <Button variant='contained' color='secondary' onClick={controller.submit}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
