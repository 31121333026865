import i18next from "i18next";
import ar from "./navigation-i18n/ar";
import en from "./navigation-i18n/en";
import tr from "./navigation-i18n/tr";

i18next.addResourceBundle("en", "navigation", en);
i18next.addResourceBundle("tr", "navigation", tr);
i18next.addResourceBundle("ar", "navigation", ar);

const navigationConfig = [
  {
    id: "createOrder",
    title: "CREATE ORDER",
    type: "item",
    url: "/createOrder",
  },
  {
    id: "productionLog",
    title: "PRODUCTION LOG",
    type: "item",
    url: "/productionLog",
  },
  {
    id: "report",
    title: "REPORTS",
    type: "collapse",
    children: [
      {
        id: "report.dailyReceivedCutComplete",
        title: "DAILY RECEIVED, CUT, AND COMPLETE",
        type: "item",
        url: "/report/dailyReceivedCutComplete",
        end: true,
      },
      {
        id: "report.inventoryByFabricID",
        title: "INVENTORY BY FABRIC ID",
        type: "item",
        url: "/report/inventoryByFabricID",
        end: true,
      },
      {
        id: "report.productionsToReview",
        title: "PRODUCTION TO REVIEW",
        type: "item",
        url: "/report/productionToReview",
      },
      {
        id: "report.saleByFabricID",
        title: "SALES BY FABRIC ID",
        type: "item",
        url: "/report/salesByFabricID",
      },
      {
        id: "report.salesByRetailerBySalesperson",
        title: "SALE BY RETAILER BY SALESPERSON",
        type: "item",
        url: "/report/salesByRetailerBySalesperson",
      },
      {
        id: "report.shirtsOnOrderSummary",
        title: "SHIRTS ON ORDER SUMMARY",
        type: "item",
        url: "/report/shirtsOnOrderSummary",
      },
    ],
  },
  {
    id: "lookup",
    title: "MANAGE LOOKUPS",
    type: "collapse",
    children: [
      {
        id: "lookup.contacts",
        title: "CONTACTS",
        type: "item",
        url: "/lookup/contacts",
      },
      {
        id: "lookup.inventory",
        title: "INVENTORY",
        type: "item",
        url: "/lookup/inventory",
      },
      {
        id: "lookup.retailers",
        title: "RETAILERS",
        type: "item",
        url: "/lookup/retailers",
      },
    ],
  },
  {
    id: "admin",
    title: "ADMIN",
    type: "collapse",
    children: [
      {
        id: "admin.importFabric",
        title: "IMPORT FABRIC ID INFO",
        type: "item",
        url: "/admin/importFabric",
      },
      {
        id: "admin.updateInventory",
        title: "UPDATE ONLINE INVENTORY",
        type: "item",
        url: "/admin/updateInventory",
      },
      {
        id: "admin.refreshDataFromProduction",
        title: "REFRESH DATA FROM PRODUCTION",
        type: "item",
        url: "/admin/refreshDataFromProduction",
      },
    ],
  },
  // {
  //   id: 'example-component',
  //   title: 'Example',
  //   translate: 'EXAMPLE',
  //   type: 'item',
  //   icon: 'heroicons-outline:star',
  //   url: 'example',
  // },
];

export default navigationConfig;
