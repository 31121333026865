import { useContext, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import FusePageCarded from "src/app/@core/@fuse/FusePageCarded.FullWidth";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import { ModuleContext, initialModuleData, moduleDataContext } from "src/app/@core/@contexts/module.context";
import TableRow from "@mui/material/TableRow";
import { saveXLSX, toAPIDate, toMMDDYYYY, toWeekDay } from "src/app/@core/common";
import { DomainContext } from "src/app/@core/@contexts/domain.context";
import { DatePicker } from "src/app/@core/forms/controls/datePicker";
import { TBodyCell } from "src/app/@core/forms/dataTable/dataTable.control";
import { ReportBodyDailyReceivedCutComplete, ReportHeader } from "src/app/@core/forms/report/report.control";
import { ReportDailyShirtDialog, ReportDailyShirtsDialogController, ReportDailyShirtsDialogModel } from "./dailyShirtsReport.dialog";
import { TextSelectMultiInput } from 'src/app/@core/forms/controls/textSelectMultiInput';
import { comboLists } from "src/app/@domain/consts/comboLists";

export const ReportDailyReceivedCutCompletePage = () => {

  const frmModel = useForm({ mode: "onChange", defaultValues: {} });
  initialModuleData.loadFormModel(frmModel);

  const [store, setStore] = useState(initialModuleData);
  const [gvItems, setGvItems] = useState([]);
  const [reportSummary, setReportSummary] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState(null);

  const { api, spinner, dropdown } = useContext(DomainContext);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));

  const frmCriteria = useForm({
    mode: "onChange", defaultValues: {
      startDate: new Date().setDate(new Date().getDate() - 27),
      endDate: new Date(),
      availability: ''
    }
  });

  const { control, getValues } = frmCriteria;

  const [reportDailyShirtsDialogModel, setReportDailyShirtsDialogModel] = useState(new ReportDailyShirtsDialogModel());
  const reportDailyShirtsDialogController = new ReportDailyShirtsDialogController(reportDailyShirtsDialogModel, setReportDailyShirtsDialogModel);
  const [selectedShirtTypes, setSelectedShirtTypes] = useState([]);

  /**
   * 
   * @param {*} data 
   */
  const loadData = async data => {

    try {

      spinner.show();
      const result = await api.reportApi.getDailyReceivedCutComplete(data);
      setReportSummary(result.item1);
      setGvItems(result.item2);
      moduleDataContext.moduleGvData(result.item2);
    } catch (err) { console.log(err); }
    finally { spinner.hide(); }

  };

  /**
   *
   */
  const contextObj = useMemo(() => {
    moduleDataContext.bootstrap(store, setStore);
    moduleDataContext.loadModelDataFunc = loadData;
    return moduleDataContext;
  }, [store]);

  /**
   *
   */
  const onSearch = async () => {

    const obj = getValues();
    obj.shirtTypes = selectedShirtTypes;

    const criteria = {
      startDate: toAPIDate(obj.startDate),
      endDate: toAPIDate(obj.endDate),
      shirtTypes: obj.shirtTypes,
    };
    setSearchCriteria(criteria);
    loadData(criteria);

  };

  /**
   *
   */
  const onExport = async () => {
    try {
      spinner.show();
      const result = await api.reportApi.exportDailyReceivedCutComplete(searchCriteria);
      saveXLSX(result, 'DailyReceivedCutCompleteReport');
    } catch (err) { console.log(err); }
    finally { spinner.hide(); }
  };

  /**
   * 
   * @param {*} reportDate 
   * @param {*} storedProc 
   */
  const onViewChild = async (reportDate, storedProc) => {
    await reportDailyShirtsDialogController.show(reportDate, searchCriteria.shirtTypes, storedProc);
  }

  /**
   * 
   * @param {*} storedProc 
   */
  const onViewChildAverage = async storedProc => {
    await reportDailyShirtsDialogController.showAverage(searchCriteria.startDate, searchCriteria.endDate, searchCriteria.shirtTypes, storedProc);
  }

  const columns = [
    { id: "productionDay", align: "center", disablePadding: false, label: "", sort: true },
    { id: "productionDate", align: "center", disablePadding: false, label: "Production Date", sort: true },
    { id: "wholeSale", align: "center", disablePadding: false, label: "Wholesale Received", sort: true, width: 100 },
    { id: "retail", align: "center", disablePadding: false, label: "Retail Received", sort: true, width: 100 },
    { id: "other", align: "center", disablePadding: false, label: "Other Received", sort: true, width: 100 },
    { id: "production", align: "center", disablePadding: false, label: "Total Received", sort: true, width: 100 },
    { id: "shirtsCut", align: "center", disablePadding: false, label: "Total Cut", sort: true, width: 100 },
    { id: "total", align: "center", disablePadding: false, label: "Total Complete", sort: true, width: 100 },
    { id: "productionDayLastYear", align: "center", disablePadding: false, label: "", sort: true },
    { id: "productionDateLastYear", align: "center", disablePadding: false, label: "Production Date", sort: true },
    { id: "wholeSaleLastYear", align: "center", disablePadding: false, label: "Wholesale Received", sort: true, width: 100 },
    { id: "retailLastYear", align: "center", disablePadding: false, label: "Retail Received", sort: true, width: 100 },
    { id: "otherLastYear", align: "center", disablePadding: false, label: "Other Received", sort: true, width: 100 },
    { id: "productionLastYear", align: "center", disablePadding: false, label: "Total Received", sort: true, width: 100 },
    { id: "shirtsCutLastYear", align: "center", disablePadding: false, label: "Total Cut", sort: true, width: 100 },
    { id: "totalLastYear", align: "center", disablePadding: false, label: "Total Complete", sort: true, width: 100 },
  ];

  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const ReportCriteria = props => {

    const { frmCriteria } = props;

    return <FormProvider {...frmCriteria}>
      <DatePicker id="startDate" label="Report Start Date" control={control} />
      &nbsp;
      <DatePicker id="endDate" label="Report End Date" control={control} />
      &nbsp;
      <TextSelectMultiInput id="shirtTypes" label="Shirt Types" control={control} items={comboLists.reportShirtTypes} title="Select Shirt Types"
        selectedItems={selectedShirtTypes} setSelectedItems={setSelectedShirtTypes} />
    </FormProvider>

  }

  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const ReportCell = props => <div className="btn-view-child-report" onClick={() => onViewChild(props.reportDate, props.storedProc)}>{props.value}</div>

  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const ReportRow = props => {

    const { item } = props;

    return <TableRow hover tabIndex={-1} key={item.itemID}>
      <TBodyCell align="center" value={toWeekDay(item.productionDate)} />
      <TBodyCell align="center" value={toMMDDYYYY(item.productionDate)} />
      <TBodyCell align="center" value={<ReportCell reportDate={item.productionDate} value={item.wholesale} storedProc="pGetWholesaleShirtsReceived" />} />
      <TBodyCell align="center" value={<ReportCell reportDate={item.productionDate} value={item.retail} storedProc="pGetRetailShirtsReceived" />} />
      <TBodyCell align="center" value={<ReportCell reportDate={item.productionDate} value={item.other} storedProc="pGetOtherRetailShirtsReceived" />} />
      <TBodyCell align="center" value={<ReportCell reportDate={item.productionDate} value={item.total} storedProc="pGetShirtsReceived" />} />
      <TBodyCell align="center" value={<ReportCell reportDate={item.productionDate} value={item.shirtsCut} storedProc="pGetShirtsCut" />} />
      <TBodyCell align="center" value={<ReportCell reportDate={item.productionDate} value={item.production} storedProc="pGetShirtsComplete" />} />
      <TBodyCell align="center" value={toWeekDay(item.productionDateLastYear)} />
      <TBodyCell align="center" value={toMMDDYYYY(item.productionDateLastYear)} />
      <TBodyCell align="center" value={<ReportCell reportDate={item.productionDateLastYear} value={item.wholesaleLastYear} storedProc="pGetWholesaleShirtsReceived" />} />
      <TBodyCell align="center" value={<ReportCell reportDate={item.productionDateLastYear} value={item.retailLastYear} storedProc="pGetRetailShirtsReceived" />} />
      <TBodyCell align="center" value={<ReportCell reportDate={item.productionDateLastYear} value={item.otherLastYear} storedProc="pGetOtherRetailShirtsReceived" />} />
      <TBodyCell align="center" value={<ReportCell reportDate={item.productionDateLastYear} value={item.totalLastYear} storedProc="pGetShirtsReceived" />} />
      <TBodyCell align="center" value={<ReportCell reportDate={item.productionDateLastYear} value={item.shirtsCutLastYear} storedProc="pGetShirtsCut" />} />
      <TBodyCell align="center" value={<ReportCell reportDate={item.productionDateLastYear} value={item.productionLastYear} storedProc="pGetShirtsComplete" />} />
    </TableRow>
  }

  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const ReportCellSummary = props => <div className="btn-view-child-report" onClick={() => onViewChildAverage(props.storedProc)}>{props.value}</div>

  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const ReportSummary = props => {

    const { item } = props;

    return (
      <TableRow tabIndex={-1} key={-1}>
        <TBodyCell align="center" value="" />
        <TBodyCell align="center" value="" />
        <TBodyCell align="center" value={<ReportCellSummary value={item.wholesale} storedProc="pGetWholesaleShirtsAverageReport" />} />
        <TBodyCell align="center" value={<ReportCellSummary value={item.retail} storedProc="pGetRetailShirtsReceivedAverageReport" />} />
        <TBodyCell align="center" value={<ReportCellSummary value={item.other} storedProc="pGetOtherRetailShirtsReceivedAverageReport" />} />
        <TBodyCell align="center" value={<ReportCellSummary value={item.total} storedProc="pGetShirtsReceivedAverageReport" />} />
        <TBodyCell align="center" value={<ReportCellSummary value={item.shirtsCut} storedProc="pGetShirtsCutAverageReport" />} />
        <TBodyCell align="center" value={<ReportCellSummary value={item.production} storedProc="pGetShirtsCompleteAverageReport" />} />
        <TBodyCell align="center" value="" />
        <TBodyCell align="center" value="" />
        <TBodyCell align="center" value={<ReportCellSummary value={item.wholesaleLastYear} storedProc="pGetWholesaleShirtsAverageReport" />} />
        <TBodyCell align="center" value={<ReportCellSummary value={item.retailLastYear} storedProc="pGetRetailShirtsReceivedAverageReport" />} />
        <TBodyCell align="center" value={<ReportCellSummary value={item.otherLastYear} storedProc="pGetOtherRetailShirtsReceivedAverageReport" />} />
        <TBodyCell align="center" value={<ReportCellSummary value={item.totalLastYear} storedProc="pGetShirtsReceivedAverageReport" />} />
        <TBodyCell align="center" value={<ReportCellSummary value={item.shirtsCutLastYear} storedProc="pGetShirtsCutAverageReport" />} />
        <TBodyCell align="center" value={<ReportCellSummary value={item.productionLastYear} storedProc="pGetShirtsCompleteAverageReport" />} />
      </TableRow>
    )
  }

  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const ReportSummaryPercent = props => {

    const { item } = props;

    return (
      <TableRow tabIndex={-1} key={-1}>
        <TBodyCell align="center" value="" />
        <TBodyCell align="center" value="" />
        <TBodyCell align="center" value={item.wholesalePercent + '%'} />
        <TBodyCell align="center" value={item.retailPercent + '%'} />
        <TBodyCell align="center" value={item.otherPercent + '%'} />
        <TBodyCell align="center" value={item.totalPercent + '%'} />
        <TBodyCell align="center" value={item.shirtsCutPercent + '%'} />
        <TBodyCell align="center" value={item.productionPercent + '%'} />
        <TBodyCell align="center" value="" />
        <TBodyCell align="center" value="" />
        <TBodyCell align="center" value="" />
        <TBodyCell align="center" value="" />
        <TBodyCell align="center" value="" />
        <TBodyCell align="center" value="" />
        <TBodyCell align="center" value="" />
        <TBodyCell align="center" value="" />
      </TableRow>
    )
  }

  return <ModuleContext.Provider value={contextObj}>

    <ReportDailyShirtDialog controller={reportDailyShirtsDialogController} />

    <FusePageCarded
      header={<ReportHeader title="Daily Received, Cut, and Complete" reportCriteria={<ReportCriteria frmCriteria={frmCriteria} />} onSearch={onSearch} />}
      content={<ReportBodyDailyReceivedCutComplete columns={columns}
        gvItems={gvItems}
        dailySummaryItems={reportSummary}
        onExport={onExport}
        ReportRow={ReportRow}
        DailyReportSummary={ReportSummary}
        DailyReportSummaryPercent={ReportSummaryPercent}
      />}
      scroll={isMobile ? "normal" : "content"}
    />
  </ModuleContext.Provider>

};
