import { APIBase } from './@base.api';

export class InventoryApi extends APIBase {

  /**
   * 
   */
  constructor(context) {
    super(context);
    this.endPoints = {
      getAll: 'Inventory/GetAll',
      insert: 'Inventory/Insert',
      update: 'Inventory/Update',
      delete: 'Inventory/Delete',
      get: 'Inventory/get',
      search: 'Inventory/Search',
      getInventoryEdit: 'Inventory/GetInventoryEdit',
      updatePhysicalInventory: 'Inventory/UpdatePhysicalInventory',
      importFabrics: 'Inventory/ImportFabrics',
      updateOnlineInventory: 'Inventory/UpdateOnlineInventory',
      rollbackImportFabrics: 'Inventory/RollbackImportFabrics',
      refreshDataFromProduction: 'Inventory/RefreshDataFromProduction',
    };
  
  }

  /**
   * 
   * @returns 
   */
  refreshDataFromProduction = () => this.httpGet(this.endPoints.refreshDataFromProduction);

  /**
   * 
   * @returns 
   */
  rollbackImportFabrics = () => this.httpUpload(this.endPoints.rollbackImportFabrics);

  /**
   * 
   * @param {*} data 
   * @returns 
   */
  updateOnlineInventory = data => this.httpUpload(this.endPoints.updateOnlineInventory, data);

  /**
   * 
   * @param {*} data 
   * @returns 
   */
  importFabrics = data => this.httpUpload(this.endPoints.importFabrics, data);

  /**
   * 
   * @param {*} data 
   * @returns 
   */
  updatePhysicalInventory = data => this.httpPost(this.endPoints.updatePhysicalInventory, data);

  /**
   * 
   * @returns 
   */
  getInventoryEdit = data => this.httpPost(this.endPoints.getInventoryEdit, data);

  /**
   * 
   * @returns 
   */
  search = data => this.httpPost(this.endPoints.search, data);
  

}
