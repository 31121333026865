import { useContext, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Paper, Table, TableBody, TablePagination } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import FusePageCarded from "src/app/@core/@fuse/FusePageCarded.FullWidth";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import { TBodyCell, THead } from "src/app/@core/forms/dataTable/dataTable.control";
import { isUoN, isUNE, toBool, toMMDDYYYY, stableSort } from "src/app/@core/common";
import { NoWrapControl } from "src/app/@core/forms/controls/noWrapControl";
import { NoDataFound } from "src/app/@core/forms/controls/noDataFound";
import { ContactEdit } from "./contact.edit";
import { ContactDeavtivate } from "./deactivate.contact";
import { DomainContext } from "src/app/@core/@contexts/domain.context";
import { ModuleContext, initialModuleData, moduleDataContext } from "src/app/@core/@contexts/module.context";
// import ContactsHeader from "./contacts.header";
import FuseUtils from "@fuse/utils/FuseUtils";
import { RetailerFilter } from "src/app/@domain/@shared/controls/filters/retailer.filter";
import { dropDownModel } from "src/app/@domain/models/dropdown.model";
import { ContactAdd } from "./contact.add";
import { PHeader } from "src/app/@domain/@shared/controls/page/header.page";
import { PSearchInput } from "src/app/@domain/@shared/controls/page/searchInput.page";
import { PCreateButton } from "src/app/@domain/@shared/controls/page/createButton.page";
import { TPagination } from "src/app/@core/forms/dataTable/dataTable.control";
import { TextInput } from "src/app/@core/forms/controls/textInput";

export const ContactsPage = () => {

  const frmModel = useForm({ mode: "onChange", defaultValues: {} });
  initialModuleData.loadFormModel(frmModel);

  const [store, setStore] = useState(initialModuleData);
  const [gvItems, setGvItems] = useState([]);
  const [retailers, setRetailers] = useState([]);

  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDeactivate, setOpenDeactivate] = useState(false);
  const [currentObj, setCurrentObj] = useState(null);
  const [selectedRetailer, setSelectedRetailer] = useState(dropDownModel.empty);

  const [sortOrder, setSortOrder] = useState({ direction: 'asc', id: null });
  const onSort = prop => {
    const direction = (sortOrder.id === prop && sortOrder.direction === 'desc') ? 'asc' : 'desc';
    setSortOrder({ direction, id: prop });
  }

  const frmCriteria = useForm({ mode: "onChange", defaultValues: { firstName: '', lastName: '', retailerName: '' } });
  const { control, getValues } = frmCriteria;

  /**
   * 
   * @returns 
   */
  const loadData = async () => {

    try {

      spinner.show();

      const items = await api.retailerApi.getDropdownItems();
      setRetailers([{ itemID: FuseUtils.generateGUID(), id: 0, name: '' }, ...items]);

      let result = await api.contactApi.getAllSP();
      if (isUoN(result)) return;

      // if (retailer && retailer.id > 0) result = result.filter(x => x.retailerID === retailer.id);
      const firstName = getValues().firstName;
      const lastName = getValues().lastName;
      const retailerName = getValues().retailerName;

      const filtered = result.filter(x => {

        const fn = x.firstName;
        const ln = x.lastName;
        const rn = x.retailerName;

        const bFirstName = !firstName || fn && firstName && fn.trim().toLowerCase() === firstName.trim().toLowerCase();
        const bLastName = !lastName || ln && ln && ln.trim().toLowerCase() === lastName.trim().toLowerCase();
        const bRetailerName = !retailerName || rn && retailerName && rn.trim().toLowerCase() === retailerName.trim().toLowerCase();

        return bFirstName & bLastName & bRetailerName

      });

      moduleDataContext.setGvItems(filtered);
      moduleDataContext.moduleGvData(filtered);

    }
    catch (err) { console.log(err); }
    finally { spinner.hide(); }

  };

  /**
   * 
   * @param {*} item 
   * @returns 
   */
  const onRetailerChange = item => {
    if (!item) return;
    const retailer = retailers.find(x => x.id == item.id);
    setSelectedRetailer(retailer);
    loadData(retailer);
  }



  /**
   *
   */
  const contextObj = useMemo(() => {
    moduleDataContext.bootstrap(store, setStore);
    moduleDataContext.loadModelDataFunc = loadData;
    moduleDataContext.setGvItems = setGvItems;
    return moduleDataContext;
  }, [store]);

  const { api, spinner } = useContext(DomainContext);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));

  /**
   *
   */
  useEffect(() => {
    loadData();
    return () => { };
  }, []);

  /**
   * 
   */
  const onSearch = () => {

    loadData();
  }

  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const SearchCriteria = props => {

    const { frmCriteria } = props;

    return <FormProvider {...frmCriteria}>
      <TextInput id="lastName" label="Last Name" control={control} />
      <TextInput id="firstName" label="First Name" control={control} />
      <TextInput id="retailerName" label="Retailer Name" control={control} />
      <Button variant="contained" color="secondary" onClick={onSearch} style={{ marginTop: 7 }}>Search</Button>
    </FormProvider>

  }

  /**
   * Header
   * @param {*} props 
   * @returns 
   */
  const ContactsHeader = (props) => {

    const { gvItems, retailers } = props;
    const { dropdown } = useContext(DomainContext);
    const { frmModel, page, rowsPerPage, searchText } = useContext(ModuleContext);

    const { reset } = frmModel();
    const [selectedRetailer, setSelectedRetailer] = useState({ itemID: '', id: 0, name: '' });

    /**
     * 
     * @param {*} item 
     * @returns 
     */
    const onRetailerChange = item => {
      if (!item) return;
      const retailer = retailers.find(x => x.id == item.id);
      setSelectedRetailer(retailer);
      console.log(item);
    }

    return (
      <div style={{ width: "100%" }}>
        <div className="flex flex-col sm:flex-row space-y-16 sm:space-y-0 flex-1 w-full items-center justify-between container">
          <Typography className="text-24 md:text-32 font-extrabold tracking-tight">
            Contact
          </Typography>
        </div>
        <div style={{ marginBottom: 10 }}>
          <hr />
          <div className="flex flex-row container" style={{ marginTop: 10 }}>
            <SearchCriteria frmCriteria={frmCriteria} />
          </div>
        </div>
      </div>
    );
  };

  /**
   * Body
   * @param {*} props 
   * @returns 
   */
  const ContactsTable = props => {

    const { api, spinner } = useContext(DomainContext);
    const { frmModel, page, rowsPerPage, loadModelDataFunc } = useContext(ModuleContext);
    const { reset } = frmModel();


    /**
     *
     * @param {*} id
     */
    const onEdit = async (id) => {
      spinner.show();
      api.contactApi
        .get(id)
        .then((result) => {
          const obj = loadCurrentObj(result);
          reset(obj);
          setOpenEdit(true);
          setCurrentObj(result);
        })
        .finally(() => spinner.hide());
    };

    /**
     * 
     * @param {*} status 
     * @param {*} id 
     */
    const setStatus = async (status, id) => {

      const result = await api.contactApi.get(id);
      const obj = { ...result, isActive: status };
      await api.contactApi.update(obj);
      await loadModelDataFunc();

    };

    /**
     *
     * @param {*} obj
     * @returns
     */
    const loadCurrentObj = obj => {

      const result = { ...obj };
      if (!result.firstName) result.firstName = '';
      if (!result.lastName) result.lastName = '';
      if (!result.middleName) result.middleName = '';
      if (!result.contactNotes) result.contactNotes = '';
      if (!result.title) result.title = '';

      result.retailer = retailers.find(x => x.id == result.retailerID);

      setSelectedRetailer(result.retailer);
      setCurrentObj(result);

      return result;

    };

    /**
     *
     */
    const onAdd = () => {
      // reset(contactModel.createNew());
      setOpenAdd(true);
    };

    /**
     *
     * @param {*} event
     * @param {*} value
     */
    const onChangePage = (event, value) => {
      page(value);
    };

    /**
     *
     * @param {*} event
     */
    const onChangeRowsPerPage = (event) => {
      rowsPerPage(event.target.value);
    };

    if (isUoN(gvItems) || gvItems.length === 0) return <NoDataFound />;

    const columns = [
      { id: 'action', align: 'center', disablePadding: false, sort: false, width: 100 },
      { id: 'lastName', align: 'left', disablePadding: false, label: 'LastName', sort: true, width: 200 },
      { id: 'firstName', align: 'left', disablePadding: false, label: 'FirstName', sort: true, width: 200 },
      {
        id: 'retailerName', align: 'left', disablePadding: false, label: 'Retailer Name', sort: true, width: 0,
        // filter: <RetailerFilter retailers={retailers} selectedRetailer={selectedRetailer} onChange={onRetailerChange} />
      },
      { id: 'email', align: 'left', disablePadding: false, label: 'Email', sort: true, width: 400 },
      { id: 'dateCreated', align: 'left', disablePadding: false, label: 'Date Created', sort: true, width: 100 },
      { id: 'isActive', align: 'left', disablePadding: false, label: 'Active', sort: true, width: 100 },
    ];

    return (
      <div className="w-full flex flex-col min-h-full">
        <Paper sx={{ width: "100%", mb: 2, boxShadow: 'none !important' }}>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button variant="contained" color="secondary" onClick={onAdd} className="btn-export"
              startIcon={<FuseSvgIcon>heroicons-outline:plus</FuseSvgIcon>}>
              Create
            </Button>
            <TPagination gvItems={gvItems} rowsPerPage={rowsPerPage} page={page} onChangePage={onChangePage} onChangeRowsPerPage={onChangeRowsPerPage} />
          </div>
          <Table stickyHeader className="min-w-xl" size="small" aria-labelledby="tableTitle">
            <THead columns={columns} sortOrder={sortOrder} onSort={onSort} />
            <TableBody>
              {
                stableSort(gvItems, sortOrder.id, sortOrder.direction)
                  .slice(page() * rowsPerPage(), page() * rowsPerPage() + rowsPerPage())
                  .map((item) => {
                    return (
                      <TableRow hover tabIndex={-1} key={item.itemID}>
                        <TableCell className="p-4 md:p-5 cell-border-top cell-border-right" component="td" scope="row" align="center">
                          <NoWrapControl>
                            <FuseSvgIcon className="text-48 cursor-pointer" size={20} color="action" onClick={() => onEdit(item.contactID)}>
                              material-solid:edit
                            </FuseSvgIcon>
                            &nbsp;&nbsp;
                            <FuseSvgIcon className="text-48 cursor-pointer" size={20} color="secondary" onClick={() => setStatus(true, item.contactID)}>
                              material-solid:person_add_alt_1
                            </FuseSvgIcon>
                            &nbsp;&nbsp;
                            <FuseSvgIcon className="text-48 cursor-pointer" size={20} color="error" onClick={() => setStatus(false, item.contactID)}>
                              material-solid:person_remove
                            </FuseSvgIcon>
                          </NoWrapControl>
                        </TableCell>
                        <TBodyCell align="left" value={item.lastName} />
                        <TBodyCell align="left" value={item.firstName} />
                        <TBodyCell align="left" value={item.retailerName} />
                        <TBodyCell align="left" value={item.email} />
                        <TBodyCell align="left" value={toMMDDYYYY(item.dateCreated)} />
                        <TBodyCell align="left" value={toBool(item.isActive)} />
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </Paper>
      </div>

    );
  };

  /**
   *
   */
  return (
    <ModuleContext.Provider value={contextObj}>
      <ContactAdd open={openAdd} setOpen={setOpenAdd} retailers={retailers} />
      <ContactEdit open={openEdit} setOpen={setOpenEdit} currentObj={currentObj} retailers={retailers} selectedRetailer={selectedRetailer} />
      <ContactDeavtivate open={openDeactivate} setOpen={setOpenDeactivate} />
      <FusePageCarded
        header={<ContactsHeader gvItems={gvItems} retailers={retailers} />}
        content={<ContactsTable />}
        scroll={isMobile ? "normal" : "normal"}
      />
    </ModuleContext.Provider>
  );
};
