import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Table, TableBody, TableRow, TableCell, TableHead, Checkbox } from '@mui/material';
import { DialogHeaderContent } from "src/app/@core/forms/controls/dialogHeaderContent";
import { DialogBase, DialogModelBase } from 'src/app/@core/forms/dialog/@dialogBaseV2';
import { NoDataFoundTable } from 'src/app/@core/forms/dataTable/noDataFound.table';

/**
 * 
 */
export class SelectItemsDialogModel extends DialogModelBase {

  /**
   * 
   */
  constructor() {
    super();
    this.title = '';
    this.items = [];
  }

}

/**
 * 
 */
export class SelectItemsDialogController extends DialogBase {

  /**
   * 
   * @param {*} model 
   * @param {*} setModel 
   */
  constructor(model, setModel) {
    super(model, setModel);
  }

  selectAll = val => {
    const items = [...this.model.items];
    items.forEach(x => x.selected = val);
    this.updateModel(x => x.items = [...items]);
  }

  /**
   * 
   */
  submit = async () => {
    const selected = this.model.items.filter(x => x.selected);
    this.hide(selected);
  }

  /**
   * 
   * @param {*} title 
   * @param {*} items 
   * @param {*} selectedItems 
   * @returns 
   */
  show = (title, items, selectedItems) => {

    if (!items) items = [];
    if (items.length > 0) {

      items.forEach(x => {
        const item = selectedItems.find(s => s.itemID === x.itemID);
        x.selected = !item ? false : true;
      });
    }

    return super.show(x => {
      x.title = title;
      x.items = items;
    });

  }

};

/**
 *
 * @param {*} props
 * @returns
 */
export const SelectItemsDialog = props => {

  const { controller } = props;
  const { model, setModel, hide, submit, updateModel } = controller;
  const { title, items } = model;

  /**
   * 
   * @returns 
   */
  const onClose = () => hide([]);

  /**
   * 
   * @param {*} e 
   * @param {*} item 
   */
  const onItemSelectChange = (e, item) => {
    item.selected = e.target.checked;
    updateModel(x => x.items = items);
  }

  /**
   * 
   * @param {*} e 
   */
  const onSelectAll = e => {
    controller.selectAll(e.target.checked);
  }

  return (
    <Dialog
      fullScreen
      style={{ margin: "auto", maxWidth: 500, maxHeight: 600 }}
      open={controller.visible()}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description">
      <DialogTitle id="scroll-dialog-title">
        <DialogHeaderContent title={title} onClose={onClose} />
      </DialogTitle>
      <DialogContent dividers={true} style={{ padding: 0 }}>
        <div className='w-full flex flex-col min-h-full'>
          <Table stickyHeader size='small'>
            <TableHead>
              <TableRow>
                <TableCell component='th' scope='row' align='center' style={{ width: 50 }}>
                  <Checkbox onChange={e => onSelectAll(e)} />
                </TableCell>
                <TableCell component='th' scope='row' align='center'>
                  Item Name
                </TableCell>
              </TableRow>
            </TableHead>
            {items.length == 0 && <NoDataFoundTable colSpan={2} />}
            {
              items.length > 0 &&
              <TableBody>
                {items.map((item, index) => (
                  <TableRow hover key={index}>
                    <TableCell className='border-left cell-border-top cell-border-right' component='td' scope='row' align='center'>
                      <Checkbox checked={item.selected} onChange={e => onItemSelectChange(e, item)} style={{ padding: 0 }} />
                    </TableCell>
                    <TableCell className='cell-border-top cell-border-right' component='td' scope='row' align='left'>
                      {item.name}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            }
          </Table>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>Cancel</Button>
        <Button variant="contained" color="secondary" onClick={submit}>Select</Button>
      </DialogActions>
    </Dialog>
  );
};
