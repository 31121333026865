import { useState } from 'react';
import Button from '@mui/material/Button';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from '@mui/material';
import FuseSvgIcon from 'src/@fuse/core/FuseSvgIcon';
import { NoWrapControl } from 'src/app/@core/forms/controls/noWrapControl';
import {
  NotesAddDialog,
  NotesAddDialogController,
  NotesAddDialogModel,
} from './notes.add.dialog';
import {
  NotesEditDialog,
  NotesEditDialogController,
  NotesEditDialogModel,
} from './notes.edit.dialog';
import {
  NotesDeleteDialog,
  NotesDeleteDialogController,
  NotesDeleteDialogModel,
} from './notes.delete.dialog';

import { toMMDDYYYY_HHMMSS } from 'src/app/@core/common';
import { NoDataFoundTable } from 'src/app/@core/forms/dataTable/noDataFound.table';

export const NotesTable = (props) => {
  const { controller } = props;

  const [notesAddDialogModel, setNotesAddDialogModel] = useState(
    new NotesAddDialogModel(controller.model.productionID)
  );
  const [notesEditDialogModel, setNotesEditDialogModel] = useState(
    new NotesEditDialogModel(controller.model.productionID)
  );
  const [notesDeleteDialogModel, setNotesDeleteDialogModel] = useState(
    new NotesDeleteDialogModel(controller.model.productionID)
  );

  const notesAddDialogController = new NotesAddDialogController(
    notesAddDialogModel,
    setNotesAddDialogModel
  );
  const notesEditDialogController = new NotesEditDialogController(
    notesEditDialogModel,
    setNotesEditDialogModel
  );
  const notesDeleteDialogController = new NotesDeleteDialogController(
    notesDeleteDialogModel,
    setNotesDeleteDialogModel
  );

  /**
   *
   */
  const onAdd = async () => {
    const result = await notesAddDialogController.show(controller.model.order);
    if (result) controller.refreshGv();
  };

  /**
   *
   */
  const onEdit = async (item) => {
    const result = await notesEditDialogController.show(item);
    if (result) controller.refreshGv();
  };

  /**
   *
   */
  const onDelete = async (item) => {
    const result = await notesDeleteDialogController.show(item);
    if (result) controller.refreshGv();
  };

  /**
   *
   * @param {*} props
   * @returns
   */
  const Header = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell
            component='th'
            scope='row'
            align='center'
            width={50}
          ></TableCell>
          <TableCell component='th' scope='row' align='center' width={200}>
            Created At
          </TableCell>
          <TableCell component='th' scope='row' align='center'>
            Note
          </TableCell>
          <TableCell component='th' scope='row' align='center' width={200}>
            Entered By
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  /**
   *
   * @param {*} props
   * @returns
   */
  const Body = () => {
    if (!controller.notes() || controller.notes().length === 0)
      return <NoDataFoundTable colSpan={4} />;
    return (
      <TableBody>
        {controller.notes().map((item) => {
          return (
            <TableRow hover tabIndex={-1} key={item.itemID}>
              <TableCell
                className='cell-border-left cell-border-top cell-border-right'
                component='td'
                scope='row'
                align='center'
              >
                <NoWrapControl>
                  <FuseSvgIcon
                    className='text-48 cursor-pointer'
                    size={20}
                    color='action'
                    onClick={() => onEdit(item)}
                  >
                    material-solid:edit
                  </FuseSvgIcon>
                  <FuseSvgIcon
                    className='text-48 cursor-pointer'
                    size={20}
                    color='action'
                    onClick={() => onDelete(item)}
                  >
                    material-solid:delete
                  </FuseSvgIcon>
                </NoWrapControl>
              </TableCell>
              <TableCell
                className='cell-border-top cell-border-right'
                component='td'
                scope='row'
                align='center'
              >
                {toMMDDYYYY_HHMMSS(item.noteDate)}
              </TableCell>
              <TableCell
                className='cell-border-top cell-border-right'
                component='td'
                scope='row'
                align='left'
              >
                {item.noteDescription}
              </TableCell>
              <TableCell
                className='cell-border-top cell-border-right'
                component='td'
                scope='row'
                align='left'
              >
                {item.fullName}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  return (
    <div className='w-full flex flex-col'>
      <NotesAddDialog controller={notesAddDialogController} />
      <NotesEditDialog controller={notesEditDialogController} />
      <NotesDeleteDialog controller={notesDeleteDialogController} />

      <div className='w-full flex flex-row'>
        <div
          className='flex flex-col justify-end'
          style={{ fontSize: '1.2em', fontWeight: 600 }}
        >
          Notes
        </div>
        <div style={{ flex: 1, textAlign: 'right', paddingBottom: 5 }}>
          <Button
            variant='contained'
            color='secondary'
            onClick={onAdd}
            startIcon={<FuseSvgIcon>heroicons-outline:plus</FuseSvgIcon>}
          >
            Add Note
          </Button>
        </div>
      </div>
      <div className='w-full flex flex-col'>
        <Table stickyHeader size='small'>
          <Header />
          <Body />
        </Table>
      </div>
    </div>
  );
};
