import moment from 'moment';

export const emptyString = '';

/**
 * 
 */
export const DateExt = {

  /**
   * 
   * @param {*} count 
   * @returns 
   */
  getByMonths: count =>  new Date(new Date().setMonth(new Date().getMonth() + count))
  
}

/**
 * 
 */
export const GUID = {

  /**
   * 
   */
  newID: () => {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
      (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
    );
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} filename 
 */
export const saveXLSX = (data, filename) => {

  filename = `${filename}_${moment().format('YYYYMMDD_ms')}.xlsx`;
  const file = new Blob([data], { type: "application/octet-stream" });
  const download = document.createElement('a');
  download.href = URL.createObjectURL(file);
  download.setAttribute('download', filename);
  document.body.appendChild(download);
  download.click();
  download.parentNode.removeChild(download);

}

/**
 * 
 * @param {*} items 
 * @param {*} sortBy 
 * @param {*} direction 
 * @returns 
 */
export const stableSort = (items, sortBy, direction) => {

  if (!sortBy) return items;

  const result = items.sort((a, b) => {

    const v1 = a[sortBy];
    const v2 = b[sortBy];

    const nameA = isUoN(v1) ? 0 : (typeof(v1) !== "string" ? v1 : v1.trim().toUpperCase());
    const nameB = isUoN(v2) ? 0 : (typeof(v2) !== "string" ? v2 : v2.trim().toUpperCase());

    return direction == 'asc' ? (nameA < nameB ? -1 : 1) : (nameA < nameB ? 1 : -1);

  });

  return result;

}


/**
 * 
 * @param {*} err 
 * @returns 
 */
export const fail = err => {
  return { succeeded: false, error: err, data: [] };
}

/**
 *
 * @param val
 */
export const isUoN = val => val === undefined || val === null;

/**
 *
 * @param val
 */
export const isUNE = val => {
  // Check undefined or null
  if (isUoN(val)) {
    return true;
  }

  // Check string length = 0
  if (typeof val === 'string' || val instanceof String) {
    if (val.length === 0) {
      return true;
    }
  }

  // Check array length = 0
  if (Array.isArray(val)) {
    if (val.length === 0) {
      return true;
    }
  }

  if (typeof val === 'object' || val instanceof Object) {
    if (Object.keys(val).length === 0) {
      return true;
    }
  }

  return false;
};

/**
 *
 * @param {*} str
 */
export const isStringJSON = (str) => {
  try {
    return !!(JSON.parse(str) && str);
  } catch (e) {
    return false;
  }
}

export const prepareObject = (objectForm) => {
  return Object.keys(objectForm).reduce((acc, key) => {
    if (!isUNE(objectForm[key])) {
      acc[key] = objectForm[key];
    }
    return acc;
  }, {});
}

export const fullAddress = (user) => {
  return `${user.address} ${user.city}, ${user.stateProvince} ${user.postalCode}`;
}

export const fromEntries = (iterable) => {
  return [...iterable].reduce((obj, [key, val]) => {
    obj[key] = val;
    return obj;
  }, {});
};

/**
 * 
 * @param {*} val 
 * @returns 
 */
export const toBool = val => {
  if (isUoN(val)) return 'FALSE';
  return val ? 'TRUE' : 'FALSE';
}

/**
 * 
 * @param {*} obj 
 * @returns 
 */
export const getFullName = obj => {
  if (!obj) return '';
  return `${!obj.firstName ? '' : obj.firstName} ${!obj.lastName ? '' : obj.lastName}`.trim();
}

/**
 * 
 * @param {*} obj 
 * @returns 
 */
export const getFullNameByLastName = obj => {
  if (!obj) return '';
  return `${!obj.lastName ? '' : obj.lastName}, ${!obj.firstName ? '' : obj.firstName}`.trim();
}

/**
 * 
 * @param {*} val 
 */
export const toWeekDay = val => {
  if (isUoN(val)) return emptyString;
  try { return moment(new Date(val)).format('dddd') } catch { return emptyString }
}

/**
 * 
 * @param {*} val 
 */
export const toMMDDYYYY = val => {
  if (isUoN(val)) return emptyString;
  try { return moment(new Date(val)).format('MM/DD/YYYY') } catch { return emptyString }
}

/**
 * 
 * @param {*} val 
 */
export const toMMDDYYYY_HHMMSS = val => {
  if (isUoN(val)) return emptyString;
  try { return moment(new Date(val)).format('MM/DD/YYYY hh:mm:ss') } catch { return emptyString }
}


/**
 * 
 * @returns 
 */
export const createDate = () => new Date(new Date().toDateString());

/**
 * 
 * @param {*} val 
 * @returns 
 */
export const toAPIDate = val => {

  if (!val) return null;

  const d = new Date(val);
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const date = d.getDate()
  return new Date(year + '-' + month + '-' + date);

}

/**
 * 
 * @param {*} img 
 * @returns 
 */
export const toLocalImg = img => !img ? '' : img.replace('http://orderentry.hamiltonshirts.com', 'https://orderentry2.hamiltonshirts.com');

/**
 * 
 * @param {*} fabricID 
 * @returns 
 */
export const getImgFromID = fabricID => `https://orderentry2.hamiltonshirts.com//images/fabricswatches/${fabricID}.jpg`;

/**
 * 
 * @returns
 */
const generateGUID = () => {
  const S4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
  return S4() + S4();
}
