import { FormControl } from '@mui/base';
import { FormControlLabel, FormLabel, Radio, RadioGroup, Table, TableBody, TableRow, TableCell, TableHead } from '@mui/material';

import { NoDataFoundTable } from 'src/app/@core/forms/dataTable/noDataFound.table';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const OrderAddStep04 = (props) => {

  const { localModel, setLocalModel } = props;
  const { fabrics, cutTrial } = localModel;
  
  /**
   * 
   * @param {*} item 
   */
  const onFabricTrialChange = item => {

    const items = [...fabrics];
    for (const prop in items) {
      const fabric = items[prop];
      if (!fabric) continue;
      if (fabric.fabricID === item.fabricID) fabric.trial = true;
      else fabric.trial = false;
    }

    setLocalModel({ ...localModel, ...{ fabrics: items } });

  }

  /**
   * 
   * @param {*} val 
   */
  const onCutTrialChange = val => setLocalModel({ ...localModel, cutTrial: val });

  return (
    <div style={{ margin: '20px 10px', padding: '10px 40px', border: '1px solid #cbcbcb', borderRadius: 10, }}>
      {
        fabrics.length > 1 &&
        <div className='flex flex-row'>
          <FormControl className='flex flex-row'>
            <FormLabel id='demo-row-radio-buttons-group-label' style={{ marginTop: 10, marginRight: 20 }}>
              Cut Trial
            </FormLabel>
            <RadioGroup row defaultValue='0' name='radio-buttons-group'>
              <FormControlLabel value='1' control={<Radio checked={cutTrial} onChange={() => onCutTrialChange(true)} />} label='Yes' />
              <FormControlLabel value='0' control={<Radio checked={!cutTrial} onChange={() => onCutTrialChange(false)} />} label='No' />
            </RadioGroup>
          </FormControl>
        </div>
      }
      <div className='flex flex-col'>
        {localModel.cutTrial &&
          <div style={{ fontWeight: 600, margin: '10px 0 5px 0' }}>
            Please select trial shirt
          </div>
        }
        <div className='w-full flex flex-col min-h-full'>
          <Table stickyHeader size='small'>
            <TableHead>
              <TableRow>
                {localModel.cutTrial && <TableCell component='th' scope='row' align='center' style={{ width: 50 }}></TableCell>}
                <TableCell component='th' scope='row' align='center' style={{ width: 50 }}>
                  Quantity
                </TableCell>
                <TableCell component='th' scope='row' align='center'>
                  Fabric ID
                </TableCell>
                <TableCell component='th' scope='row' align='center'>
                  Fabric ID Description
                </TableCell>
                <TableCell component='th' scope='row' align='center'>
                  Availability
                </TableCell>
                <TableCell component='th' scope='row' align='center'>
                  Line Item Type
                </TableCell>
              </TableRow>
            </TableHead>
            {fabrics.length == 0 && <NoDataFoundTable colSpan={6} />}
            {
              fabrics.length > 0 &&
              <TableBody>
                {fabrics.map((item, index) => {

                  return <TableRow hover key={index}>
                    {localModel.cutTrial &&
                      <TableCell className='border-left cell-border-top cell-border-right' component='td' scope='row' align='center'>
                        <Radio checked={item.trial} onChange={() => onFabricTrialChange(item)} />
                      </TableCell>
                    }
                    <TableCell className='cell-border-top cell-border-right' component='td' scope='row' align='center'>
                      {item.quantity}
                    </TableCell>
                    <TableCell
                      className='cell-border-top cell-border-right' component='td' scope='row' align='left'>
                      {item.fabricID}
                    </TableCell>
                    <TableCell className='cell-border-top cell-border-right' component='td' scope='row' align='left'>
                      {item.fabricIDDescription}
                    </TableCell>
                    <TableCell className='cell-border-top cell-border-right' component='td' scope='row' align='left'>
                      {item.availablity}
                    </TableCell>
                    <TableCell className='cell-border-top cell-border-right' component='td' scope='row' align='left'>
                      {item.shirtType}
                    </TableCell>
                  </TableRow>

                })}
              </TableBody>
            }
          </Table>
        </div>
        <div style={{ fontWeight: 600, margin: '10px 0 5px 0' }}>
          <div>
            New Shirts: {fabrics.filter(x => x.shirtType !== 'Repair').reduce((partialSum, obj) => partialSum + obj.quantity, 0)}
          </div>
          <div>
            Repair Shirts: {fabrics.filter(x => x.shirtType === 'Repair').reduce((partialSum, obj) => partialSum + obj.quantity, 0)}
          </div>
        </div>
      </div>
      <br />
    </div>
  );
};
