import { useContext, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import FusePageCarded from "src/app/@core/@fuse/FusePageCarded.FullWidth";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import { ModuleContext, initialModuleData, moduleDataContext } from "src/app/@core/@contexts/module.context";
import TableRow from "@mui/material/TableRow";
import { saveXLSX, toAPIDate, toMMDDYYYY } from "src/app/@core/common";
import { DomainContext } from "src/app/@core/@contexts/domain.context";
import { DatePicker } from "src/app/@core/forms/controls/datePicker";
import { TextInput } from "src/app/@core/forms/controls/textInput";
import { TBodyCell, TBodyCellEdit } from "src/app/@core/forms/dataTable/dataTable.control";
import { ReportBody, ReportHeader } from "src/app/@core/forms/report/report.control";
import { OrderEditDialog, OrderEditDialogController, OrderEditDialogModel, } from 'src/app/@domain/modules/order/edit/@order.edit.dialog';
import { TextSelectMultiInput } from 'src/app/@core/forms/controls/textSelectMultiInput';
import { comboLists } from "src/app/@domain/consts/comboLists";

export const ReportSalesByFabricIDPage = () => {

  const frmModel = useForm({ mode: "onChange", defaultValues: {} });
  initialModuleData.loadFormModel(frmModel);

  const [store, setStore] = useState(initialModuleData);
  const [gvItems, setGvItems] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState(null);

  const { api, spinner, dropdown } = useContext(DomainContext);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));

  const frmCriteria = useForm({
    mode: "onChange",
    defaultValues: {
      startDate: new Date().setMonth(new Date().getMonth() - 6),
      endDate: new Date(),
      availability: ''
    }
  });
  const { control, getValues } = frmCriteria;

  const [orderEditDialogModel, setOrderEditDialogModel] = useState(new OrderEditDialogModel());
  const orderEditDialogController = new OrderEditDialogController(orderEditDialogModel, setOrderEditDialogModel);
  const [selectedShirtTypes, setSelectedShirtTypes] = useState([]);

  /**
   * 
   * @param {*} data 
   */
  const loadData = async data => {

    try {

      spinner.show();
      const result = await api.reportApi.getSalesByFabricID(data);
      setGvItems(result);
      moduleDataContext.moduleGvData(result);
    } catch (err) { console.log(err); }
    finally { spinner.hide(); }

  };

  /**
   *
   */
  const contextObj = useMemo(() => {
    moduleDataContext.bootstrap(store, setStore);
    moduleDataContext.loadModelDataFunc = loadData;
    return moduleDataContext;
  }, [store]);

  /**
   *
   */
  const onSearch = async () => {

    console.log(selectedShirtTypes);
    const obj = getValues();
    obj.shirtTypes = selectedShirtTypes;

    const criteria = {
      startDate: toAPIDate(obj.startDate),
      endDate: toAPIDate(obj.endDate),
      shirtTypes: obj.shirtTypes,
      fabricID: obj.fabricID
    };
    setSearchCriteria(criteria);
    loadData(criteria);

  };

  /**
   *
   */
  const onExport = async () => {
    try {
      spinner.show();
      const result = await api.reportApi.exportSalesByFabricID(searchCriteria);
      saveXLSX(result, 'SalesByFabridIDReport');
    } catch (err) { console.log(err); }
    finally { spinner.hide(); }
  };

  /**
   * 
   * @param {*} item 
   */
  const onEdit = async item => {
    const url = window.location.origin + '/editOrder?orderID=' + item.orderID;
    window.open(url, '_blank');
  }

  const columns = [
    { id: "actionID", align: "center", disablePadding: false, label: "", sort: true, width: 70 },
    { id: "lastName", align: "center", disablePadding: false, label: "Last Name", sort: true, width: 200 },
    { id: "firstName", align: "center", disablePadding: false, label: "First Name", sort: true, width: 200 },
    { id: "retailerName", align: "center", disablePadding: false, label: "Retailer", sort: true, width: 0 },
    { id: "shirtType", align: "center", disablePadding: false, label: "Shirt Type", sort: true, width: 200 },
    { id: "orderDate", align: "center", disablePadding: false, label: "Order Date", sort: true, width: 100 },
    { id: "prodTypeText", align: "center", disablePadding: false, label: "Prod Type", sort: true, width: 150 },
    { id: "prodStatus", align: "center", disablePadding: false, label: "Prod Status", sort: true, width: 200 },
    { id: "lastStatusUpdateDate", align: "center", disablePadding: false, label: "Updated Date", sort: true, width: 70 },
  ];

  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const ReportCriteria = props => {

    const { frmCriteria } = props;

    return <FormProvider {...frmCriteria}>
      <DatePicker id="startDate" label="Report Start Date" control={control} />
      &nbsp;
      <DatePicker id="endDate" label="Report End Date" control={control} />
      &nbsp;
      <TextSelectMultiInput id="shirtTypes" label="Shirt Types" control={control} items={comboLists.reportShirtTypes} title="Select Shirt Types"
        selectedItems={selectedShirtTypes} setSelectedItems={setSelectedShirtTypes} />
      &nbsp;
      <TextInput id="fabricID" label="Fabric ID" control={control} />
    </FormProvider>

  }

  /**
   * 
   * @param {*} props 
   * @returns 
   */
  const ReportRow = props => {

    const { item } = props;

    return <TableRow hover tabIndex={-1} key={item.itemID}>
      <TBodyCellEdit onEdit={() => onEdit(item)} />
      <TBodyCell align="left" value={item.lastName} />
      <TBodyCell align="left" value={item.firstName} />
      <TBodyCell align="left" value={item.retailerName} />
      <TBodyCell align="left" value={item.shirtType} />
      <TBodyCell align="center" value={toMMDDYYYY(item.orderDate)} />
      <TBodyCell align="center" value={item.prodTypeText} />
      <TBodyCell align="center" value={item.prodStatus} />
      <TBodyCell align="center" value={toMMDDYYYY(item.lastStatusUpdateDate)} />
    </TableRow>
  }

  return <ModuleContext.Provider value={contextObj}>

    <OrderEditDialog controller={orderEditDialogController} />

    <FusePageCarded
      header={<ReportHeader title="Sales By Fabric ID" reportCriteria={<ReportCriteria frmCriteria={frmCriteria} />} onSearch={onSearch} />}
      content={<ReportBody columns={columns} gvItems={gvItems} onExport={onExport} ReportRow={ReportRow} />}
      scroll={isMobile ? "normal" : "content"}
    />
  </ModuleContext.Provider>

};
