import { useState } from 'react';
import Button from '@mui/material/Button';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from '@mui/material';
import FuseSvgIcon from 'src/@fuse/core/FuseSvgIcon';
import { NoWrapControl } from 'src/app/@core/forms/controls/noWrapControl';
import {
  NotesOrderEditAddDialog,
  NotesOrderEditAddDialogController,
  NotesOrderEditAddDialogModel,
} from './notesOrderEdit.add.dialog';
import {
  NotesOrderEditEditDialog,
  Notes1EditDialogController,
  NotesOrderEditDialogModel,
} from './notesOrderEdit.edit.dialog';
import {
  Notes1DeleteDialog,
  Notes1DeleteDialogController,
  NotesOrderEditDeleteDialogModel,
} from './notesOrderEdit.delete.dialog';

import { toMMDDYYYY_HHMMSS } from 'src/app/@core/common';
import { NoDataFoundTable } from 'src/app/@core/forms/dataTable/noDataFound.table';

export const NotesOrderEditTable = (props) => {

  const { controller } = props;
  const [notesOrderEditAddDialogModel, setNotesOrderEditAddDialogModel] = useState(
    new NotesOrderEditAddDialogModel(controller.model.order)
  );
  const [notesOrderEditEditDialogModel, setNotesOrderEditEditDialogModel] = useState(
    new NotesOrderEditDialogModel()
  );
  const [notesOrderEditDeleteDialogModel, setNotesOrderEditDeleteDialogModel] = useState(
    new NotesOrderEditDeleteDialogModel()
  );

  const notesOrderEditAddDialogController = new NotesOrderEditAddDialogController(
    notesOrderEditAddDialogModel,
    setNotesOrderEditAddDialogModel
  );
  const notesOrderEditEditDialogController = new Notes1EditDialogController(
    notesOrderEditEditDialogModel,
    setNotesOrderEditEditDialogModel
  );
  const notesOrderEditDeleteDialogController = new Notes1DeleteDialogController(
    notesOrderEditDeleteDialogModel,
    setNotesOrderEditDeleteDialogModel
  );

  /**
   *
   */
  const onAdd = async () => {
    console.log(controller.model);
    const result = await notesOrderEditAddDialogController.show(controller.model);
    if (result) controller.refreshGvNotes();
  };

  /**
   *
   */
  const onEdit = async (item) => {
    const result = await notesOrderEditEditDialogController.show(item);
    if (result) controller.refreshGvNotes();
  };

  /**
   *
   */
  const onDelete = async (item) => {
    const result = await notesOrderEditDeleteDialogController.show(item);
    if (result) controller.refreshGvNotes();
  };

  /**
   *
   * @param {*} props
   * @returns
   */
  const Header = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell
            component='th'
            scope='row'
            align='center'
            width={50}
          ></TableCell>
          <TableCell component='th' scope='row' align='center' width={200}>
            Created At
          </TableCell>
          <TableCell component='th' scope='row' align='center' width={150}>
            Production Type
          </TableCell>
          <TableCell component='th' scope='row' align='center'>
            Note
          </TableCell>
          <TableCell component='th' scope='row' align='center' width={200}>
            Entered By
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  /**
   *
   * @param {*} props
   * @returns
   */
  const Body = () => {
    if (!controller.notes() || controller.notes().length === 0)
      return <NoDataFoundTable colSpan={4} />;

    return (
      <TableBody>
        {controller.notes().map((item) => {
          return (
            <TableRow hover tabIndex={-1} key={item.notesID}>
              <TableCell
                className='cell-border-left cell-border-top cell-border-right'
                component='td'
                scope='row'
                align='center'
              >
                <NoWrapControl>
                  <FuseSvgIcon
                    className='text-48 cursor-pointer'
                    size={20}
                    color='action'
                    onClick={() => onEdit(item)}
                  >
                    material-solid:edit
                  </FuseSvgIcon>
                  <FuseSvgIcon
                    className='text-48 cursor-pointer'
                    size={20}
                    color='action'
                    onClick={() => onDelete(item)}
                  >
                    material-solid:delete
                  </FuseSvgIcon>
                </NoWrapControl>
              </TableCell>
              <TableCell
                className='cell-border-top cell-border-right'
                component='td'
                scope='row'
                align='center'
              >
                {toMMDDYYYY_HHMMSS(item.noteDate)}
              </TableCell>
              <TableCell
                className='cell-border-top cell-border-right'
                component='td'
                scope='row'
                align='left'
              >
                {item.prodType}
              </TableCell>
              <TableCell
                className='cell-border-top cell-border-right'
                component='td'
                scope='row'
                align='left'
              >
                {item.noteDescription}
              </TableCell>
              <TableCell
                className='cell-border-top cell-border-right'
                component='td'
                scope='row'
                align='left'
              >
                {item.fullName}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  return (
    <div className='w-full flex flex-col'>
      <NotesOrderEditAddDialog controller={notesOrderEditAddDialogController} />
      <NotesOrderEditEditDialog controller={notesOrderEditEditDialogController} />
      <Notes1DeleteDialog controller={notesOrderEditDeleteDialogController} />

      <div className='w-full flex flex-row'>
        <div
          className='flex flex-col justify-end'
          style={{ fontSize: '1.2em', fontWeight: 600 }}
        >
          Notes
        </div>
        <div style={{ flex: 1, textAlign: 'right', paddingBottom: 5 }}>
          <Button
            variant='contained'
            color='secondary'
            onClick={onAdd}
            startIcon={<FuseSvgIcon>heroicons-outline:plus</FuseSvgIcon>}
          >
            Add Note
          </Button>
        </div>
      </div>
      <div className='w-full flex flex-col'>
        <Table stickyHeader size='small'>
          <Header />
          <Body />
        </Table>
      </div>
    </div>
  );
};
