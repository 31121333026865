import { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TablePagination from "@mui/material/TablePagination";
import { motion } from "framer-motion";
import { DateExt, createDate, isUNE } from "src/app/@core/common";
import { DomainContext } from "src/app/@core/@contexts/domain.context";
import { ModuleContext } from "src/app/@core/@contexts/module.context";
import { SelectInput } from "src/app/@core/forms/controls/selectInput";
import { DatePicker } from "src/app/@core/forms/controls/datePicker";
import { comboLists } from "src/app/@domain/consts/comboLists";

/**
 *
 * @param {*} props
 * @returns
 */
const InventoryHeader = (props) => {
  const { gvItems} = props;
  const { api, dropdown } = useContext(DomainContext);
  const { page, rowsPerPage, executeSearchFunc, setSearchCriteria } = useContext(ModuleContext);

  const criteriaModel = useForm({
    mode: "onChange",
    defaultValues: {
      fabricID01: '',
      fabricID02: '',
      fabricID03: '',
      fabricID04: '',
      fabricID05: '',
      fabricID06: '',
      fabricID07: '',
      fabricID08: '',
      fabricID09: '',
      fabricID10: '',
      fabricID11: '',
      fabricID12: '',
      fabricID13: '',
      fabricID14: '',
      fabricID15: '',
      fabricID16: '',
      fabricID17: '',
      fabricID18: '',
      fabricID19: '',
      fabricID20: '',
      currentAvailabilityStatus: 'All',
      collection: 'All Collections',
      startDate: DateExt.getByMonths(-6),
      endDate: createDate(),
    },
  });
  const { control, getValues } = criteriaModel;

  /**
   * 
   */
  /**
   *
   * @param {*} event
   * @param {*} value
   */
  const onChangePage = (event, value) => {
    page(value);
  };

  /**
   *
   * @param {*} event
   */
  const onChangeRowsPerPage = (event) => {
    rowsPerPage(event.target.value);
  };

  /**
   * 
   */
  const onSearch = async () => {
    const criteria = getValues();
    setSearchCriteria(criteria)
    await executeSearchFunc(api.inventoryApi.search, criteria);    
  };

  /**
   * 
   * @param {*} e 
   */
  const onFabricKeyDown = e => {
    if (e.keyCode !== 13) return;
    onSearch();
  }

  /**
   *
   * @param {*} props
   * @returns
   */
  const FabricID = (props) => (
    <Controller
      name={props.id}
      control={props.control}
      render={({ field }) => (
        <input
          {...field}
          id={props.id}          
          style={{
            width: "10%",
            height: 25,
            marginRight: props.marginRight,
            paddingLeft: 5,
            paddingRight: 5,
            border: "1px solid #d9d9d9",
            borderRadius: 5,
          }}
          onKeyDown={onFabricKeyDown}
        />
      )}
    />
  );

  return (
    <div style={{ width: "100%" }}>
      <div className="flex flex-col sm:flex-row space-y-16 sm:space-y-0 flex-1 w-full items-center justify-between container">
        <Typography
          component={motion.span}
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.2 } }}
          delay={300}
          className="text-24 md:text-32 font-extrabold tracking-tight"
        >
          Inventory
        </Typography>
      </div>
      <hr />
      <div className="container" style={{ marginTop: 5 }}>
        <div>FabricIDs</div>
        <div className="flex flex-row">
          <FabricID id="fabricID01" control={control} marginRight={3} />
          <FabricID id="fabricID02" control={control} marginRight={3} />
          <FabricID id="fabricID03" control={control} marginRight={3} />
          <FabricID id="fabricID04" control={control} marginRight={3} />
          <FabricID id="fabricID05" control={control} marginRight={3} />
          <FabricID id="fabricID06" control={control} marginRight={3} />
          <FabricID id="fabricID07" control={control} marginRight={3} />
          <FabricID id="fabricID08" control={control} marginRight={3} />
          <FabricID id="fabricID09" control={control} marginRight={3} />
          <FabricID id="fabricID10" control={control} marginRight={0} />
        </div>
        <div className="flex flex-row" style={{ marginTop: 3 }}>
          <FabricID id="fabricID11" control={control} marginRight={3} />
          <FabricID id="fabricID12" control={control} marginRight={3} />
          <FabricID id="fabricID13" control={control} marginRight={3} />
          <FabricID id="fabricID14" control={control} marginRight={3} />
          <FabricID id="fabricID15" control={control} marginRight={3} />
          <FabricID id="fabricID16" control={control} marginRight={3} />
          <FabricID id="fabricID17" control={control} marginRight={3} />
          <FabricID id="fabricID18" control={control} marginRight={3} />
          <FabricID id="fabricID19" control={control} marginRight={3} />
          <FabricID id="fabricID20" control={control} marginRight={0} />
        </div>
        <br />
        <div className="flex flex-row">
          <SelectInput id="currentAvailabilityStatus" label="Current Availability Status" control={control} items={comboLists.availablility} defaultValues={"%"} />
          <SelectInput id="collection" label="Collection" control={control} items={comboLists.collection} defaultValues={""} />
          <DatePicker id="startDate" label="Start Date" control={control} />
          <DatePicker id="endDate" label="End Date" control={control} />
          <motion.div initial={{ opacity: 0, x: 20 }} animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}>
            <Button variant="contained" color="secondary" onClick={onSearch} style={{ marginTop: 7 }}>
              Search
            </Button>
          </motion.div>
        </div>
      </div>
      <hr />
      <div className="flex flex-col w-full sm:w-auto sm:flex-row space-y-16 sm:space-y-0 flex-1 items-center justify-end space-x-8">
        <TablePagination
          className="shrink-0"
          component="div"
          count={isUNE(gvItems) ? 0 : gvItems.length}
          rowsPerPage={rowsPerPage()}
          page={page()}
          backIconButtonProps={{ "aria-label": "Previous Page" }}
          nextIconButtonProps={{ "aria-label": "Next Page" }}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </div>
    </div>
  );
};

export default InventoryHeader;
