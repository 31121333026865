import { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ProdStatusControl } from 'src/app/@domain/modules/prodStatus/@prodStatus.control';
import { ShirtsControl } from 'src/app/@domain/modules/shirt/@shirts.control';
import { NotesOrderEditTable } from 'src/app/@domain/modules/notesOrderEdit/notesOrderEdit.table';
import { DialogHeaderContent } from 'src/app/@core/forms/controls/dialogHeaderContent';
import { DialogBase, DialogModelBase } from 'src/app/@core/forms/dialog/@dialogBaseV2';
import { ConvertToTrialDialog, ConvertToTrialDialogController, ConvertToTrialDialogModel } from 'src/app/@domain/modules/order/edit/convertToTrial.dialog';
import { ConvertToNoTrialDialog, ConvertToNoTrialDialogController, ConvertToNoTrialDialogModel } from './convertToNoTrial.dialog';
import { OrderEditSection } from './orderEditSection';

/**
 *
 */
export class OrderEditDialogModel extends DialogModelBase {

  /**
   *
   */
  constructor() {
    super();
    this.contacts = [];
    this.retailers = [];
    this.shirts = [];
    this.order = { orderID: 0 };
    this.shirtUser = null;
    this.retailer = null;
    this.salesperson = null;
    this.notes = [];
    this.orderID = '';
    this.productions = [];
  }
}

/**
 *
 */
export class OrderEditDialogController extends DialogBase {

  /**
   *
   * @param {*} model
   * @param {*} setModel
   */
  constructor(model, setModel) {
    super(model, setModel);
    this.dispatch = useDispatch();
    this.loadForm = null;
    this.convertToTrialDialogController = null;
    this.convertToNoTrialDialogController = null;
    this.scrollRef = useRef();
    this.scrollTop = 0;
  }

  contacts = () => this.model.contacts;
  retailers = () => this.model.retailers;
  order = () => this.model.order;
  shirtUser = () => this.model.shirtUser;
  retailer = () => this.model.retailer;
  salesperson = () => this.model.salesperson;
  shirts = () => this.model.shirts;
  notes = () => this.model.notes;
  productions = () => this.model.productions;

  /**
   * 
   * @param {*} orderID 
   * @returns 
   */
  getOrder = async orderID => {
    return await this.api().orderApi.getEditOrder(orderID);
  }

  /**
   * 
   * @param {*} prodState 
   */
  updateActiveProduction = async prodState => {

    try {

      this.spinner().show();
      const orderID = this.order().orderID;
      const order = await this.getOrder(orderID);
      const shirts = await this.api().orderApi.getShirts(orderID);
      const notes = await this.api().productionNoteApi.getUserNotesByOrderID(orderID);
      const productions = await this.api().orderApi.getProdActivities(orderID);

      this.updateModel((x) => {
        x.order.isCutTrial = prodState;
        x.order.orderStatus = order.orderStatus;
        x.shirts = shirts;
        x.notes = notes;
        x.productions = productions
      });

    } catch (err) { console.log(err); }
    finally { this.spinner().hide(); }

  }

  /**
   *
   * @param {*} orderID
   * @returns
   */
  loadFormData = async orderID => {

    try {

      this.spinner().show();

      if (this.loadForm) this.loadForm();

      const contacts = await this.api().contactApi.getEditOrderShirtUsers();
      const retailers = await this.api().retailerApi.getDropdownItems();
      const order = await this.getOrder(orderID);
      const shirts = await this.api().orderApi.getShirts(orderID);
      const notes = await this.api().productionNoteApi.getUserNotesByOrderID(orderID);
      const productions = await this.api().orderApi.getProdActivities(orderID);
      const shirtUser = contacts.find(x => x.contactID === order.contactID);

      let retailer = retailers.find(x => x.id === order.retailerID);
      if (!retailer) retailer = { itemID: '', id: '', name: '' };
      const salesperson = contacts.find(x => x.contactID === order.salesPersonID);

      return {
        contacts,
        retailers,
        order,
        shirts,
        shirtUser,
        retailer,
        salesperson,
        notes,
        orderID,
        productions,
      };
    } catch (err) { console.log(err); }
    finally { this.spinner().hide(); }

  };

  /**
   *
   */
  refreshGvNotes = async () => {

    try {

      this.spinner().show();

      const notes = await this.api().productionNoteApi.getUserNotesByOrderID(this.model.orderID);
      if (notes) this.updateModel((x) => (x.notes = notes));

      this.restoreScrollPosition();

    } catch (err) { console.log(err); }
    finally { this.spinner().hide(); }

  };

  /**
   *
   */
  refreshGvProdActivities = async () => {

    try {

      this.spinner().show();

      const result = await this.api().orderApi.getProdActivities(this.model.orderID);
      if (result) this.updateModel(x => x.productions = result );

      this.restoreScrollPosition(); 

    } catch (err) { console.log(err); }
    finally { this.spinner().hide(); }

  };

  /**
   * 
   * @returns 
   */
  restoreScrollPosition = () => setTimeout(() => this.scrollRef.current.scrollTop = this.scrollTop, 10);

  /**
   * 
   */
  refresh = async () => {

    try {

      this.spinner().show();
      const result = await this.loadFormData(this.order().orderID);

      this.updateModel(x => {
        x.contacts = result.contacts;
        x.retailers = result.retailers;
        x.order = result.order;
        x.shirts = result.shirts;
        x.shirtUser = result.shirtUser;
        x.retailer = result.retailer;
        x.salesperson = result.salesperson;
        x.notes = result.notes;
        x.orderID = result.orderID;
        x.productions = result.productions;
      });

      this.restoreScrollPosition(); 

    } catch (err) { console.log(err); }
    finally { this.spinner().hide(); }

  }

  /**
   *
   * @returns
   */
  show = async (orderID) => {

    const result = await this.loadFormData(orderID);

    return super.show((x) => {
      x.contacts = result.contacts;
      x.retailers = result.retailers;
      x.order = result.order;
      x.shirts = result.shirts;
      x.shirtUser = result.shirtUser;
      x.retailer = result.retailer;
      x.salesperson = result.salesperson;
      x.notes = result.notes;
      x.orderID = result.orderID;
      x.productions = result.productions;
    });
  };

}

/**
 *
 * @param {*} props
 * @returns
 */
export const OrderEditDialog = (props) => {

  const { controller } = props;
  const { close } = controller;

  const [convertToTrialDialogModel, setConvertToTrialDialogModel] = useState(new ConvertToTrialDialogModel());
  const convertToTrialDialogController = new ConvertToTrialDialogController(convertToTrialDialogModel, setConvertToTrialDialogModel);
  controller.convertToTrialDialogController = convertToTrialDialogController;

  const [convertToNoTrialDialogModel, setConvertToNoTrialDialogModel] = useState(new ConvertToNoTrialDialogModel());
  const convertToNoTrialDialogController = new ConvertToNoTrialDialogController(convertToNoTrialDialogModel, setConvertToNoTrialDialogModel);
  controller.convertToNoTrialDialogController = convertToNoTrialDialogController;

  /**
   * 
   * @param {*} e 
   */
  const onScroll = e => controller.scrollTop = e.target.scrollTop;   

  return (
    <>
      <ConvertToTrialDialog controller={convertToTrialDialogController} />
      <ConvertToNoTrialDialog controller={convertToNoTrialDialogController} />

      <Dialog
        fullScreen
        style={{ margin: 'auto', maxWidth: 1300, maxHeight: 800 }}
        open={controller.visible()}
        onClose={close}
        scroll='paper'
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <DialogTitle id='scroll-dialog-title'>
          <DialogHeaderContent title='Edit Order' onClose={close} />
        </DialogTitle>
        <DialogContent dividers={true} style={{ padding: 0 }} ref={controller.scrollRef} onScroll={onScroll}>
          <div style={{ padding: 20 }}>
            <OrderEditSection controllerBase={controller} />
            <hr style={{ margin: '15px 0 15px 0' }} />
            <ShirtsControl controller={controller} />
            <hr style={{ margin: '20px 0' }} />
            <NotesOrderEditTable controller={controller} />
            <hr style={{ margin: '20px 0' }} />
            <ProdStatusControl controller={controller} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} variant='contained' color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
